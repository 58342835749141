import React from "react";
import { Card, Select, } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, FormGroup,  Spinner } from 'reactstrap';
import Media from 'react-media';

//import filterFactory from 'react-bootstrap-table2-filter';
//import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import { services } from '../../../services/services'
import { numberWithCommas } from '../../../helpers/index';

import { authenticationService } from "../../../services/authentication-service";

const currentUser = authenticationService.currentUserValue


//const confirm = Modal.confirm;

//const FormItem = Form.Item;

//const moment = require('moment')

class StockGeneral extends React.Component {
    state = {
        stocksGeneral: [],
        agences: [],

        waitingEndMsg: '',
        isLoading: true,
    }


    componentDidMount() {
        services.getStocksGeneral()
            .then(stocksGeneral => {
                //console.log('stocksGeneral', stocksGeneral)
                this.setState({ stocksGeneral })
                setTimeout(() => {

                    // //console.log("real articles", factures)
                     if (!stocksGeneral || !Array.isArray(stocksGeneral)
                         || !stocksGeneral.length > 0) {
                         this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucun Stock actuellement !" })
                     }
                 }, 500)
            })
            services.getAllAgences()
            .then(agences => {
                //console.log('agences', agences)
                this.setState({ agences })
            })
    }

    handleChangeAgence = (value) => {
        this.setState({codAgence:value})
        this.setState({ isLoading: true, waitingEndMsg: '' })
        services.getStocksGeneral(value)
            .then(stocksGeneral => {
                //console.log('stocksGeneral', stocksGeneral)
                this.setState({ stocksGeneral })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                     if (!stocksGeneral || !Array.isArray(stocksGeneral)
                         || !stocksGeneral.length > 0) {
                         this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucun Stock actuellement !" })
                     }
                 }, 4000)
            })
    }


    render() {
        const {
            stocksGeneral,
            agences, 
            codAgence,
            isLoading,
            waitingEndMsg,

        } = this.state


       // const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

      

        const columnsStocksGeneral = [{
            dataField: 'id',
            text: ' ID',
            headerAttrs: { width: 50 },
            //footer: 'TOTAL'
        },
        {
            dataField: 'article.designation',
            text: 'Article',
            headerAttrs: { width: 300 },
            sort: true,
            //footer: ''

        },
        {
            dataField: 'agence.nomAgence',
            text: 'Agence',
            headerAttrs: { width: 200 },
            //footer: ''

        },
        
        {
            dataField: 'qteStock',
            text: 'Stock',
            headerAttrs: { width: 100 },
            //footerAlign: 'center',
            //footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            //footerClasses: 'demo-row-even'
        },
        
        /*{
            dataField: '',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        <Button
                            type="primary"
                            //fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            //className="btn btn-success btn-sm"
                            onClick={() => this.handleView(row)}
                        >
                            <i className="icon icon-custom-view" />
                        </Button>
                        <Button
                            type="primary"
                            //fullWidth
                            variant="contained"
                            color="danger"
                            size="large"
                            //className="btn btn-success btn-sm"
                            onClick={() => this.handleUpdateOpen(row)}
                        >
                            <i className="icon icon-edit" />
                        </Button>
                        <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Approvisionnement?" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                            cancelText="Non">
                            <Button
                                type="danger"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleDelete(row)}
                            >
                                <i className="icon icon-alert-new" />
                            </Button>

                        </Popconfirm>

                        <Button
                            type="danger"
                            //fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            //className="btn btn-success btn-sm"
                            onClick={() => this.handleValider(row)}
                        >
                            <i className="icon icon-check-circle-o" />
                        </Button>

                    </div>
                );
            },

        }*/

        ];


        const columnsStocksGeneralPhone = [

            {
                dataField: 'article.designation',
                text: 'Article',
                sort: true,
            },

            {
                dataField: 'agence.nomAgence',
                text: 'Agence'
            },
            
            {
                dataField: 'qteStock',
                text: 'Stock'
            },
            /*{
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="large"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                            <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Approvisionnement?" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                                </Button>

                            </Popconfirm>

                        </div>
                    );
                },

            }*/

        ];

        const defaultSorted = [{
            dataField: 'designation',
            order: 'asc'
        }];

        let totalChiffre = stocksGeneral && stocksGeneral.length ?
            stocksGeneral.reduce(function (prev, cur) {
                return prev + parseInt(cur.qteStock)
            }, 0) : 0


        return (
            <div>
                <Card title= {`${(currentUser && currentUser.agence && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE")? 'Stocks de toutes les Agences' : 'Stock Agence' }`}>

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={stocksGeneral}
                            columns={columnsStocksGeneral}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>

                                            <Col md={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                            {(currentUser && currentUser.agence && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE")&&
                                            <FormGroup>
                                            <Select
                                            
                                            value={codAgence}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '150%' }}
                                            placeholder="Filtrer par Agence"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeAgence}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            <Option value=''>TOUTES</Option>
                                            {
                                                
                                                agences && agences.length ?
                                                    agences.map((agence, k) => {
                                                        return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                    }) : null
                                            }
                                            

                                        </Select>
                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                </FormGroup>
                                        }
                                        
                                            </Col>
                   

                                        </Row>

                                        {stocksGeneral &&
                                                Array.isArray(stocksGeneral) &&
                                                stocksGeneral.length > 0 &&
                                        <Row>
                                            <Col>
                                            <h3 style={{ color: "red",fontWeight: "bold" }}><u>Stock Total</u>: {(totalChiffre === 1)? 0 : `${numberWithCommas(totalChiffre)}`}</h3>
                                            </Col>
                                        </Row>
                                        } 

                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {
                                            stocksGeneral &&
                                                Array.isArray(stocksGeneral) &&
                                                stocksGeneral.length > 0 ?
                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            //footerClasses="footer-class"
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                            hover
                                            condensed
                                            headerWrapperClasses="foo"
                                            noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                        //filter={filterFactory()}
                                        />
                                        :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={stocksGeneral}
                            columns={columnsStocksGeneralPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                <i className="fa fa-search" style={{ margin: 10 }}></i>

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>

                                        </Row>

                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>

            </div>
        )
    }
}


export default StockGeneral;
