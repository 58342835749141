import React from "react";
import {Route, Switch} from "react-router-dom";

import FacturePrincipal from "./Facture";
import FacturesGlobale from "./FactureGlobale";
import DetailsFacture from "./DetailsFacture";
import detailsFactureGlobale from "./DetailsFactureGlobale";


 
const Facture = ({match}) => (
  <Switch>
    <Route path={`${match.url}/principal`}    component={FacturePrincipal}/>
    <Route path={`${match.url}/detailsPrincipal`}    component={DetailsFacture}/>
    <Route path={`${match.url}/factureGlobale`}   component={FacturesGlobale}/> 
    <Route path={`${match.url}/detailsFactureGlobale`}   component={detailsFactureGlobale}/> 
    

    
   
  </Switch>
);

export default Facture;
