import React from "react";
import { Card, Form, Input,  Button, Modal, Select, message, InputNumber, DatePicker, Radio } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, FormGroup, Label, ListGroup, Table, Spinner } from 'reactstrap';
import Media from 'react-media';

//import filterFactory from 'react-bootstrap-table2-filter';
//import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'; 

import 'moment/locale/fr-ca';
import locale from 'antd/es/date-picker/locale/fr_FR';

import { services } from '../../../services/services';
import { authenticationService } from "../../../services/authentication-service";
//import { use_logiciel } from '../../../env'

import { numberWithCommas } from '../../../helpers/index';


const currentUser = authenticationService.currentUserValue

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

//const confirm = Modal.confirm;

const FormItem = Form.Item;

const moment = require('moment')

class FacturesGlobale extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        loading: false,
        isListArticleVisible: false,
        isDetailsArticleVisible: false,
        isValidationValidation: false,
        repartitions: [],
        listeArticlesSelected: [],
        articles: [],
        articlesCodPro: [],
        agences: [],
        formLayout: 'horizontal',

        //usuel: "1",
        codTyp: "1",
        libCat: '',
        codeCat: '',
        emailFour: '',
        refFactFour: '',
        codAgence: '',
        modRegl: '',
        codmod: '',
        tauxAib: 0,
        fraisTransport: 0,
        cleRepartition: 0,
        dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        dateEnCours: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        //codAgenceChange: '',
        etatCmde: 'EN COURS',
        puRemise: 0,
        autreFrais: 0,
        montantRemise: 0,
        //puRemise: 0,
        qtCommander: 0,

        factures: [],
        clients: [],
        //agences: [],
        gender: 'M',
        raisonSociale: '',
        codClient: '',
        mentionClient: 'NOUVEAU CLIENT',
        typeClient: 'CLIENT ORDINAIRE',
        usuel: '1',
        dateNaissance: moment('01/01/2021', dateFormatList[0]),
        ifu: '',

        waitingEndMsg: '',
        isLoading: true,
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        // const dateEnCours= moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
        services.getFacturesGlobale('', this.state.dateEnCours)
            .then(factures => {
                //console.log('factures', factures)
                this.setState({ factures })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                     if (!factures || !Array.isArray(factures)
                         || !factures.length > 0) {
                         this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Facture actuellement pour la date du ${this.state.dateEnCours}!` })
                     }
                 }, 500)

                services.getClients()
                    .then(clients => {
                        //console.log('clients', clients)
                        this.setState({ clients })
                    })

                services.getStocksAgence()
                    .then(articles => {
                        //console.log('articles', articles)
                        this.setState({ articles })
                    })
                services.getAgences()
                    .then(agences => {
                        //console.log('agences', agences)
                        this.setState({ agences })
                    })
            })

    }


    handleAddOpen = () => {
        this.setState({
            dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            codClient: '',
            observation: '',
            modRegl: '',
            codmod: '',
            montantRemise: 0,
            fraisTransport: 0,
            autreFrais: 0,
            tauxAib: 0,
            totalTva: 0,
            netHT: 0,
            tauxRemise: 0,
            netCommercial: 0,
            montantTtc: 0,
            montantAib: 0,
            netaPayer: 0,
            montFact: 0,
            refFact: '',
            isAddModalVisible: !this.state.isAddModalVisible,
            articlesCodPro: [],
            listeArticlesSelected: [],
        });
    };

    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codCat: '',

            libCat: '',

        })
    }

    handleAddFacture = () => {
        const {
            //dateFact,
            codClient,
            observation,
            modRegl,
            codmod,
            montantRemise,
            fraisTransport,
            autreFrais,
            tauxAib,
            refFact,

            listeArticlesSelected,

        } = this.state


        if (codClient === '') {
            message.warning('Veuillez sélectionner une Client', 5)
            return;
        }

        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null) {
            message.warning('Vous devriez au moins sélectionner un article', 5)
            return;
        }

        const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt((cur.puUtilise * cur.qtCommander * cur.tauxTva) / 100)
            }, 0) : 0

        const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.puUtilise * cur.qtCommander)
            }, 0) : 0

        const tauxRemise = ((100 * parseInt(montantRemise) / netHT))

        const netCommercial = (parseInt(netHT) - parseInt(montantRemise))

        const montantTtc = (parseInt(netCommercial) + parseInt(totalTva) + parseInt(fraisTransport) + parseInt(autreFrais))

        const montantAib = ((tauxAib * montantTtc) / 100)


        const netaPayer = parseInt(montantTtc) + parseInt(montantAib) /*+ parseInt(fraisTransport)*/;

        const montFact = netaPayer;


        let req = {
            dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            codClient,
            observation,
            modRegl,
            codmod,
            montantRemise,
            fraisTransport,
            autreFrais,
            tauxAib,
            totalTva,
            netHT,
            tauxRemise,
            netCommercial,
            montantTtc,
            montantAib,
            netaPayer,
            montFact,
            refFact,


            listeArticlesSelected,


        }
        ////console.log('REQUETE', req)

        ////console.log('REQ ADD APPROVISIONNEMENT', req)
        services.addFacture(req)
            .then(result => {
                //console.log(result)
                this.setState({
                    dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    codClient: '',
                    observation: '',
                    modRegl: '',
                    codmod: '',
                    montantRemise: '',
                    fraisTransport: '',
                    autreFrais: '',
                    tauxAib: '',
                    totalTva: '',
                    netHT: '',
                    tauxRemise: '',
                    netCommercial: '',
                    montantTtc: '',
                    montantAib: '',
                    netaPayer: '',
                    montFact: '',
                    refFact: '',

                    listeArticlesSelected: [],

                    isAddModalVisible: !this.state.isAddModalVisible,

                })
                message.success('Facture ajouter avec succès', 10)

                services.getFacturesGlobale(this.state.codAgenceChange, this.state.dateEnCours)
                    .then(factures => {
                        this.setState({ factures })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de l\'ajout, Veuillez réessayer plus tard', 10)
            });
    }

    /* handleUpdateRepartition = () => {
         const {
             repartitionId,
             dateRepartition,
             observation,
             codAgence,
 
             listeArticlesSelected,
 
         } = this.state
 
 
         if (codAgence === '') {
             message.warning('Veuillez sélectionner une Agence', 5)
             return;
         }
 
         if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null) {
             message.warning('Vous devriez au moins sélectionner un article', 5)
             return;
         }
 
 
         let req = {
             repartitionId,
             dateRepartition,
             observation,
             codAgence,
 
             listeArticlesSelected,
         }
 
         services.updateRepartition(repartitionId, req)
             .then(result => {
                 //console.log(result)
                 this.setState({
                     repartitionId: '',
                     dateRepartition: '',
                     observation: '',
                     codAgence: '',
                     listeArticlesSelected: [],
                     isUpdateModalVisible: !this.state.isUpdateModalVisible,
 
                 })
                 message.success('Répartition modifier avec succès', 10)
 
                 services.getFacturesGlobale()
                     .then(factures => {
                         this.setState({ factures })
                     })
 
             })
 
             .catch(() => {
                 message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
             });
     }
 
     handleDelete = (row) => {
         this.setState({
             repartitionId: row.id,
         })
 
     }
 
     handleDeleteApprovisionnement = () => {
 
         const { repartitionId } = this.state
 
         services.deleteRepartition(repartitionId)
             .then(del => {
                 ////console.log('delete user', del)
                 message.success('Répartition supprimer avec succès', 10)
                 services.getFacturesGlobale()
                     .then(factures => {
                         this.setState({ factures })
                     })
 
             })
             .catch(() => {
                 message.error('Une erreur est survenue lors de la suppression, Veuillez réessayer plus tard', 10)
             });
 
     }
 
     handleValider = (row) => {
         this.setState({
             repartitionId: row.id,
             isValidationValidation: !this.state.isValidationValidation
         })
 
     }
     handleCloseValidation = () => {
         this.setState({ isValidationValidation: !this.state.isValidationValidation, codeEntre: '' })
     }
 
     handleValiderRepartition = () => {
 
         const { repartitionId } = this.state
 
         services.validerRepartition(repartitionId)
             .then(del => {
                 ////console.log('delete user', del)
                 message.success('Répartition Valider avec succès', 10)
                 this.setState({
                     isValidationValidation: !this.state.isValidationValidation
                 })
                 services.getFacturesGlobale()
                     .then(factures => {
                         this.setState({ factures })
                     })
 
             })
             .catch(() => {
                 message.error('Une erreur est survenue lors de la validation, Veuillez réessayer plus tard', 10)
             });
 
     }
 
     handleUpdateOpen = (row) => {
         this.setState({
             isUpdateModalVisible: !this.state.isUpdateModalVisible,
             repartitionId: row.id,
             dateRepartition: row.dateRepartition,
             observation: row.observation,
             codAgence: row.codAgence,
             //codAgence: `${row.codAgence}`,
             listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                 row.listeArticlesSelected.map((details, k) => {
                     return {
                         qtEntree: details.qtEntree,
                         qteStock: details && details.stock ? details.stock.qteStock : '',
                         qteStockEnCoursValidation: details && details.stock ? details.stock.qteStockEnCoursValidation : '',
                         designation: details && details.article ? details.article.designation : '',
                         codProd: details && details.article ? details.article.codPro : '',
 
                     }
                 }) : [],
 
             articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                 row.listeArticlesSelected.map((details, k) => {
                     return details && details.article ? details.article.codPro : ''
                 }) : [],
 
         })
 
     }
 
     handleCancelUpdate = () => {
         this.setState({
             isUpdateModalVisible: !this.state.isUpdateModalVisible,
             dateRepartition: '',
             observation: '',
             codAgence: '',
 
             listeArticlesSelected: [],
 
         })
     }
 
     */


    handleView = (row) => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,

            numFact: row.numFact,
            dateFact: row.dateFact,
            codClient: row.codClient,
            observation: row.observation,
            modRegl: row.modRegl,
            codmod: row.codmod,
            montantRemise: row.montantRemise,
            fraisTransport: row.fraisTransport,
            autreFrais: row.autreFrais,
            tauxAib: row.tauxAib,
            totalTva: row.totalTva,
            netHT: row.netHT,
            tauxRemise: row.tauxRemise,
            netCommercial: row.netCommercial,
            montantTtc: row.montantTtc,
            montantAib: row.montantAib,
            netaPayer: row.netaPayer,
            montFact: row.montFact,
            refFact: row.refFact,
            nomAgence: row && row.agences ? row.agences.nomAgence : '',
            nomAgent: `${row && row.userInfo ? row.userInfo.prenomAgent : ''} ${row && row.userInfo ? row.userInfo.nomAgent : ''}`,

            //codAgence: `${row.codAgence}`,
            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        //RESTE CETTE PARTIE AVEC LES AGENTS
                        //numFact: facture.id,

                        puRemise: details.puRemise,
                        qtCommander: details.qtCommander,
                        codTva: details.codTva,
                        tauxTva: details.tauxTva,
                        puAchat: details.puAchat,
                        puBrut: details.puBrut,
                        //puRemise: details.puRemise,
                        puUtilise: details.puUtilise,
                        codAgence: details.codAgence,
                        //codProd: details.codProd,

                        //qtEntree: details.qtEntree,
                        //qteStock: details && details.stock ? details.stock.qteStock : '',
                        //qteStockEnCoursValidation: details && details.stock ? details.stock.qteStockEnCoursValidation : '',
                        designation: details && details.article ? details.article.designation : '',
                        codProd: details && details.article ? details.article.codPro : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.article ? details.article.codPro : ''
                }) : [],

        })
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            dateRepartition: '',
            observation: '',
            codAgence: '',

            listeArticlesSelected: [],
        });
    }


    //clients
    handleCancelAddClient = () => {
        this.setState({
            isAddClientModalVisible: !this.state.isAddClientModalVisible,
            codClient: '',
            raisonSociale: '',
            ifu: '',
            adresse: '',
            contact: '',
            contact2: '',
            gender: 'M',
            usuel: '1',
            mentionClient: 'NOUVEAU CLIENT',
            dateNaissance: moment('01/01/2021', dateFormatList[0]),
            typeClient: 'CLIENT ORDINAIRE',
            siteWeb: '',
            email: '',
            profession: '',
        })
    }


    handleAddClient = () => {
        const {
            raisonSociale,
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,
        } = this.state


        if (raisonSociale === '') {
            message.warning('Veuillez renseigner le champ Raison sociale', 5)
            return;
        }
        if (contact === '') {
            message.warning('Veuillez renseigner le champ Téléphone', 5)
            return;
        }


        let req = {
            raisonSociale: raisonSociale.toUpperCase(),
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,

        }
        services.addClient(req)
            .then(result => {
                //console.log(result)
                this.setState({
                    raisonSociale: '',
                    ifu: '',
                    adresse: '',
                    contact: '',
                    contact2: '',
                    gender: 'M',
                    usuel: '1',
                    mentionClient: 'NOUVEAU CLIENT',
                    dateNaissance: moment('01/01/2021', dateFormatList[0]),
                    typeClient: 'CLIENT ORDINAIRE',
                    siteWeb: '',
                    email: '',
                    profession: '',
                    isAddClientModalVisible: !this.state.isAddClientModalVisible,

                })
                message.success('Client ajouter avec succès', 10)

                services.getClients()
                    .then(clients => {
                        this.setState({ clients })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de l\'ajout, Veuillez réessayer plus tard', 10)
            });
    }


    handleChangeGender = (value) => {
        this.setState({ gender: value })
    }


    onChangeMention = e => {
        ////console.log('radio4 checked', e.target.value);
        this.setState({
            mentionClient: e.target.value,
        });
    };

    handleChangeUsuel = (value) => {
        this.setState({ usuel: value })
    }

    handleChangeDate = (date, dateString) => {
        //console.log(dateString, moment(dateString).format('DD/MM/YYYY'), moment(date, dateFormatList[0]));
        this.setState({ dateNaissance: moment(dateString).format('DD/MM/YYYY') })
    }

    handleChangeTypeClient = (value) => {
        this.setState({ typeClient: value })
    }
    //FIN FOURNISSEUR


    handleChangeClient = (value) => {
        this.setState({ codClient: value })
    }

    handleChangeAib = (value) => {
        this.setState({ tauxAib: value })
    }


    handleChangeTypeReglement = (value) => {
        this.setState({ modRegl: value })
    }

    handleChangeModReglem = (value) => {
        this.setState({ codmod: value })
    }

    openListArticleModal = () => {
        services.getStocksAgence()
            .then(articles => {
                ////console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd)),
                })
            })
        this.setState({ isListArticleVisible: !this.state.isListArticleVisible })
    }


    onRechercheArticleChange = (e) => {
        services.getStocksAgence(e.target.value.toUpperCase())
            .then(articles => {
                //console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd)),
                })
            })
    }

    handleArticleSelected = () => {

        const {
            puRemise,
            qtCommander,
            codTva,

            puAchat,
            puBrut,



            qteStock,
            //qtEntree,
            qteStockEnCoursValidation,
            //puEntree,


            article,
            articles,
        } = this.state

        if (qtCommander === '' || qtCommander === 0) {
            message.warning('Veuillez préciser la Quantité à Répartir', 6)
            return;
        }
        if (qtCommander > (parseInt(qteStock) - parseInt(qteStockEnCoursValidation))) {
            message.warning('La quantité entrée est supérieure à la quantité disponible, Veuillez éffectuer un Approvisionnement d\'abord', 6)
            return;
        }

        if (parseInt(puRemise) > parseInt(puBrut) || parseInt(puRemise) === parseInt(puBrut)) {
            message.warning('Le Prix unitaire remise entrée est supérieure ou égal au prix unitaire Brut, Veuillez modifier', 6)
            return;
        }

        const articleCod = article.codProd

        let tauxTva =
            (this.state.codTva === "A" || this.state.codTva === "C" || this.state.codTva === "E" || this.state.codTva === "F") ?// &&
            "0"
            :
            //||
            //(this.state.codTva === "B" || this.state.codTva === "D") &&
            "18";


        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    puRemise: puRemise,
                    qtCommander: qtCommander,
                    codTva: codTva,
                    tauxTva: parseInt(tauxTva),
                    puAchat: puAchat,
                    puBrut: puBrut,
                    //puRemise: puRemise,
                    puUtilise: (parseInt(puBrut) - parseInt(puRemise)),

                    //qtEntree: qtEntree,
                    qteStock: qteStock,
                    qteStockEnCoursValidation: qteStockEnCoursValidation,

                    codProd: article.codProd,
                    designation: article.article ? article.article.designation : ''

                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd) && x.codProd !== articleCod),
            isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            isListArticleVisible: !this.state.isListArticleVisible,
            qtEntree: '',
        }))
        // //console.log('ArticleCod & articles', this.state.articlesCodPro, articles)
    }


    handleDeleteArticlesSelected = (article) => {

        const { listeArticlesSelected, articlesCodPro } = this.state
        //console.log('ID Art', article, listeArticlesSelected)

        this.setState({
            listeArticlesSelected: [...listeArticlesSelected.filter(x => x.codProd !== article.codProd)],
            articlesCodPro: [...articlesCodPro.filter(x => x !== article.codProd)]
        })


    }

    openDetailArticleModal = (article) => {
        this.setState({
            codTva: article.article ? article.article.codTvaVente : '',
            //tauxTva,
            puAchat: article.article ? article.article.puAchat : '',
            puBrut: parseInt(article.article ? article.article.puVente : 0),

            article: article,
            qteStock: article.qteStock,
            qteStockEnCoursValidation: article.qteStockEnCoursValidation,
            isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            qtCommander: 0,
            puRemise: 0,
            //codTva: '',
        })
    }



    handleChangeEtatCmde = (value) => {
        this.setState({ etatCmde: value });
        services.getFacturesGlobale(value, this.state.dateEnCours)
            .then(factures => {
                // //console.log('factures', repartitions)
                this.setState({ factures })
            })

    }

    handleChangeAgence = (value) => {
        this.setState({ codAgenceChange: value })
        this.setState({ isLoading: true, waitingEndMsg: '' })
        services.getFacturesGlobale(value, this.state.dateEnCours)
            .then(factures => {
                //console.log('factures', factures)
                this.setState({ factures })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                     if (!factures || !Array.isArray(factures)
                         || !factures.length > 0) {
                         this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Facture actuellement pour cette Agence!" })
                     }
                 }, 500)
            })
    }

    handleChangeDateFiltre = (date, dateString) => {
        ////console.log(dateString, moment(dateString).format('DD/MM/YYYY'), moment(date, dateFormatList[0]));
        this.setState({ dateEnCours: dateString })
        this.setState({ isLoading: true, waitingEndMsg: '' })

        services.getFacturesGlobale(this.state.codAgenceChange , dateString)
            .then(factures => {
                // //console.log('factures', repartitions)
                this.setState({ factures })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                     if (!factures || !Array.isArray(factures)
                         || !factures.length > 0) {
                         this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Facture actuellement pour la date du ${this.state.dateEnCours}!` })
                     }
                 }, 500)
            })
    }

    render() {
        const {
            isModalVisible,
            loading,

            agences,

            dateEnCours,
            dateFact,
            observation,
            codAgence,
            codAgenceChange,
            modRegl,
            // montantAp,
            codmod,
            refFact,
            qtCommander,
            puRemise,
            puBrut,
            //puUtilise,


            //etatCmde,
           // numPoint,
           // valider,
           // datValid,

            fraisTransport,
            listeArticlesSelected,
            articles,

           // repartitions,
            qteStockEnCoursValidation,



            isAddClientModalVisible,
            isAddModalVisible,
            //isUpdateModalVisible,
            isListArticleVisible,
            isDetailsArticleVisible,
            isValidationValidation,

            //qtEntree,

            codClient,
            raisonSociale,
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,
            clients,

            factures,

            qteStock,

            formLayout,

            tauxAib,
            //montantAib,
            //montantTtc,
            //netCommercial,
            //tauxRemise,
            montantRemise,
            autreFrais, //n'existe pas

            nomAgence,
            nomAgent,

            isLoading,
            waitingEndMsg,

        } = this.state

        //  //console.log('listeArticlesSelected', listeArticlesSelected)



        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.qtCommander)
            }, 0) : 0


        const totalTva = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt((cur.puUtilise * cur.qtCommander * cur.tauxTva) / 100)
            }, 0) : 0

        const netHT = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.puUtilise * cur.qtCommander)
            }, 0) : 0

        const tauxRemise = ((100 * parseInt(montantRemise) / netHT))

        const netCommercial = (parseInt(netHT) - parseInt(montantRemise))

        const montantTtc = (parseInt(netCommercial) + parseInt(totalTva) + parseInt(fraisTransport) + parseInt(autreFrais))

        const montantAib = ((tauxAib * montantTtc) / 100)


        const netaPayer = parseInt(montantTtc) + parseInt(montantAib) /*+ parseInt(fraisTransport)*/;

        //const montantAp = netaPayer;



        const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;


        const columnsFactures = [{
            dataField: 'numFact',
            text: 'N°',
            headerAttrs: { width: 50 },
        },
        {
            dataField: 'refFact',
            text: 'Référence'
        },
        {
            dataField: 'dateFact',
            text: 'Date',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                           row.dateFact /* moment(new Date(row.dateFact), 'DD/MM/YYYY').format('DD/MM/YYYY')
                        */}
                    </div>
                );
            },
        },
        {
            dataField: 'clients.raisonSociale',
            text: 'Clients'
        },
        {
            dataField: 'userInfo',
            text: 'Agents',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                            row.userInfo ? `${row.userInfo.prenomAgent} ${row.userInfo.nomAgent}` : ''
                        }
                    </div>
                );
            },
        },
        {
            dataField: 'agences.nomAgence',
            text: 'Agence'
        },

        {
            dataField: 'montFact',
            text: 'Montant Total',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div className="text-center">
                        {
                           numberWithCommas( parseInt(row.montFact) )
                        }
                    </div>
                );
            },
        },

        {
            dataField: '',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        {//currentUser.slug === 'COMMERCIAL_ROLE' && row.etatCmde === 'VALIDER' &&
                            <Button
                                title="Visualiser"
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                        }
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                            <Button
                                title="Modifier"
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                        }
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                            <Button
                                title="Valider"
                                type="danger"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                className="gx-btn-block gx-btn-yellow"
                                size="sm"
                                onClick={() => this.handleValider(row)}
                            >
                                <i className="icon icon-check-circle-o" />
                            </Button>
                        }
                        {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                            <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Répartition??" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                    title="Suppprimer"
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                    </Button>

                            </Popconfirm>
                    */}

                    </div>
                );
            },

        }

        ];


        const columnsFacturesPhone = [


            {
                dataField: 'refFact',
                text: 'Référence'
            },

            {
                dataField: 'clients.raisonSociale',
                text: 'Clients'
            },
            {
                dataField: 'montFact',
                text: 'Montant Total',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                               numberWithCommas( parseInt(row.montFact) )
                            }
                        </div>
                    );
                },
            },

            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&/ row.etatCmde === 'EN COURS' &&
                                <Button
                                    title="Modifier"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="danger"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleUpdateOpen(row)}
                                >
                                    <i className="icon icon-edit" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&* row.etatCmde === 'EN COURS' &&
                                <Button
                                    title="Valider"
                                    //type="danger"
                                    //fullWidth
                                    variant="contained"
                                    //color="primary"
                                    size="sm"
                                    className="gx-btn-block gx-btn-yellow"
                                    onClick={() => this.handleValider(row)}
                                >
                                    <i className="icon icon-check-circle-o" />
                                </Button>
                            }
                            {/*currentUser.slug === 'COMMERCIAL_ROLE' &&* row.etatCmde === 'EN COURS' &&
                                <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Approvisionnement?" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppprimer"
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="sm"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                        */ }

                        </div>
                    );
                },

            }

        ];

        const defaultSorted = [{
            dataField: 'numFact',
            order: 'desc'
        }];


        return (
            <div>
                <Card title="Liste des Factures">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={factures}
                            columns={columnsFactures}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                <FormGroup>
                                                <Select

                                                    value={codAgenceChange}
                                                    showSearch
                                                    //style={{width: 200}}
                                                    style={{ width: '140%' }}
                                                    placeholder="Filtrer par Agence"
                                                    optionFilterProp="children"
                                                    onChange={this.handleChangeAgence}
                                                    onFocus={this.handleFocus}
                                                    onBlur={this.handleBlur}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    <Option value=''>TOUTES</Option>
                                                    {
                                                        agences && agences.length ?
                                                            agences.map((agence, k) => {
                                                                return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                            }) : null
                                                    }
                                                    
                                                </Select>
                                                <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                </FormGroup>
                                                
                                            </Col>
                                            <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                <FormGroup>

                                                    <DatePicker style={{ width: '100%' }} name="dateEnCours" id="dateEnCours" value={moment(dateEnCours, dateFormatList[0])} locale={locale} onChange={this.handleChangeDateFiltre} format={dateFormatList} />
                                                    <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Date</span></h6>
                                                </FormGroup>
                                            </Col>
                                            {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
/*
                                                <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>

                                            */   }
                                        </Row>

                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {
                                            factures &&
                                                Array.isArray(factures) &&
                                                factures.length > 0 ?

                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                        //filter={filterFactory()}
                                        />
                                        :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={factures}
                            columns={columnsFacturesPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 450, width: '125%', display: 'flex' }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                <Select

                                                    value={codAgence}
                                                    showSearch
                                                    //style={{width: 200}}
                                                    style={{ width: '85%' }}
                                                    placeholder="Filtrer par Agence"
                                                    optionFilterProp="children"
                                                    onChange={this.handleChangeAgence}
                                                    onFocus={this.handleFocus}
                                                    onBlur={this.handleBlur}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    <Option value=''>TOUTES</Option>
                                                    {
                                                        agences && agences.length ?
                                                            agences.map((agence, k) => {
                                                                return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                            }) : null
                                                    }

                                                </Select>
                                                <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                            </Col>
                                            <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                 
                                                <FormGroup>

                                                    <DatePicker style={{ width: '100%' }} name="dateEnCours" id="dateEnCours" value={moment(dateEnCours, dateFormatList[0])} locale={locale} onChange={this.handleChangeDateFiltre} format={dateFormatList} />
                                                    <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Date</span></h6>
                                                    </FormGroup>
                                            </Col>
                                            {/*<Col md={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>

                                                <Select
                                                    //style={{ maxWidth: 650, width: '90%', margin: 'auto', display: 'flex', borderRadius: 50 }}

                                                    value={etatCmde}
                                                    showSearch
                                                    //style={{width: 200}}
                                                    style={{ width: '90%' }}
                                                    placeholder="Filtrer par statut"
                                                    optionFilterProp="children"
                                                    onChange={this.handleChangeEtatCmde}
                                                    onFocus={this.handleFocus}
                                                    onBlur={this.handleBlur}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >

                                                    <Option value="EN COURS">EN COURS</Option>
                                                    <Option value="VALIDER">VALIDER</Option>

                                                </Select>

                                </Col>*/}
                                            {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
/*
                                                <Col className="mt-2" md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>
                                            */ }

                                        </Row>

                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>


                <Modal
                    width={900}
                    visible={isAddModalVisible}
                    title="Facture Client"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAdd}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleAddFacture}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelAdd}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="refFact">Référence Devis</Label>
                                    <Input type="text" name="refFact" value={refFact} onChange={this.handleChange} id="refFact" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateFact">Date</Label>
                                    <Input disabled type="text" name="dateFact" id="dateFact" value={dateFact} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codClient">Client</Label>
                                    <InputGroup compact>

                                        <Select
                                            value={codClient}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Selectionner un Client"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeClient}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                clients && clients.length ?
                                                    clients.map((client, k) => {
                                                        return <Option value={client.codClient}>{client.raisonSociale}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.handleCancelAddClient} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        {/*<Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="refFactFour">Facture Fournisseur N°</Label>
                                    <Input type="text" name="refFactFour" value={refFactFour} onChange={this.handleChange} id="refFactFour" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>*/}

                        <Row form>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="modRegl">Type règlement</Label>
                                    <Select className="gx-mr-3 gx-mb-3" value={modRegl} onChange={this.handleChangeTypeReglement}>
                                        <Option value="Cash">Cash</Option>
                                        <Option value="Partiel">Partiel</Option>
                                        <Option value="Crédit">Crédit</Option>

                                    </Select>
                                    {//(modRegl === "A" || modRegl === "C" || modRegl === "E" || modRegl === "F") &&
                                        <h6 style={{ color: "black" }}><span>NB:Si la commande n'est pas payée à  l'avance, veuillez stipuler pour le Type de Règlement Crédit</span></h6>
                                    }

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codmod">Mode règlement</Label>
                                    <Select className="gx-mr-3 gx-mb-3" value={codmod} onChange={this.handleChangeModReglem}>
                                        <Option value="AUTRE">Autre</Option>
                                        <Option value="CARTE">Carte Bancaire</Option>
                                        <Option value="CHEQUE">Chèque</Option>
                                        <Option value="ESPECES">Espèces</Option>
                                        <Option value="MOMO">Mobile Money</Option>
                                        <Option value="VIREMENT">Virement</Option>
                                    </Select>

                                </FormGroup>
                            </Col>

                        </Row>


                        {/*<Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantAp">Montant Commande</Label>
                                    <Input disabled type="number" name="montantAp" value={montantAp} id="montantAp" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="idemBal">Montant règlement</Label>
                                    <Input type="text" name="idemBal" value="champ non retrouvé" id="idemBal" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>*/}

                        <Row>
                            <Col md={8}>
                                <button className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">PU Brut</th>
                                            <th className="text-center">Montant Remise</th>
                                            <th className="text-center">PU Net HT</th>
                                            <th className="text-center">Qté Commandée</th>
                                            <th className="text-center">Montant</th>
                                            <th className="text-center">Qté en Stock</th>
                                            <th className="text-center">Tva</th>
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseInt(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{numberWithCommas( article.puBrut )}</td>
                                                            <td className="text-center">{numberWithCommas( article.puRemise )}</td>
                                                            <td className="text-center">{numberWithCommas( article.puUtilise )}</td>
                                                            <td className="text-center">{article.qtCommander}</td>
                                                            <td className="text-center">{numberWithCommas( parseInt(article.puUtilise) * parseInt(article.qtCommander) )}</td>
                                                            <td className="text-center">{article.qteStock}</td>
                                                            <td className="text-center">{article.tauxTva}</td>

                                                            <Button className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col md={5}>
                                <Card title="Groupe de Taxation et TVA" className="gx-card ">
                                    <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                        <thead className="bg-primary text-white">
                                            <tr>
                                                {/**<th className="text-center"> </th> */}

                                                <th className="text-center">Base</th>
                                                <th className="text-center">Taux(%)</th>
                                                <th className="text-center">Montant</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listeArticlesSelected && listeArticlesSelected.length ?
                                                    listeArticlesSelected.map((article, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                {/** <td className="text-center"><Input onChange={(e) => this.handleChangeCheck(e, article)} className="ml-1" type="checkbox" /></td> */}

                                                                <td className="text-center">{numberWithCommas( parseInt(article.puUtilise) * parseInt(article.qtCommander) )}</td>

                                                                <td className="text-center">{article.tauxTva}</td>

                                                                <td className="text-center">{numberWithCommas( (parseInt(article.puUtilise) * parseInt(article.qtCommander) * parseInt(article.tauxTva)) / 100 )}</td>



                                                            </tr>
                                                        );
                                                    })
                                                    : null
                                            }

                                        </tbody>
                                    </Table>
                                    <FormItem className="mt-3" label="Total TVA"  {...formItemLayout}>
                                        <Input disabled type="number" name="totalTva" value={totalTva} id="totalTva" onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>

                            </Col>
                            <Col md={7}>
                                <Card title="Totaux et Taxes" className="gx-card">
                                    <FormItem label="Net HT"  {...formItemLayout}>

                                        <Input disabled type="number" name="netHT" id="netHT" value={netHT} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    <FormItem label="Montant Remise"  {...formItemLayout}>

                                        <Input type="number" name="montantRemise" id="montantRemise" value={montantRemise} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Taux Remise"  {...formItemLayout}>

                                        <Input disabled type="number" name="tauxRemise" id="tauxRemise" value={tauxRemise} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Net Commercial"  {...formItemLayout}>

                                        <Input disabled type="number" name="netCommercial" id="netCommercial" value={netCommercial} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Total TVA"  {...formItemLayout}>

                                        <Input disabled type="number" name="totalTva" value={totalTva} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    <FormItem label="Frais Transport"  {...formItemLayout}>

                                        <Input type="number" name="fraisTransport" id="fraisTransport" value={fraisTransport} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Autres Frais"  {...formItemLayout}>

                                        <Input type="number" name="autreFrais" id="autreFrais" value={autreFrais} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Montant TTC"  {...formItemLayout}>

                                        <Input disabled type="number" name="montantTtc" id="montantTtc" value={montantTtc} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="AIB"  {...formItemLayout}>

                                        <InputNumber name="tauxAib" id="tauxAib"
                                            value={tauxAib}
                                            onChange={this.handleChangeAib}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            defaultValue={0}
                                            min={0}
                                            max={100} />
                                        <Input disabled type="number" name="montantAib" id="montantAib" value={montantAib} onChange={this.handleChange} placeholder="Montant AIB" />
                                    </FormItem>


                                    <FormItem label="Net à Payer"  {...formItemLayout}>

                                        <Input disabled type="number" name="netaPayer" id="netaPayer" value={netaPayer} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>


                            </Col>
                        </Row>
                    </Form>
                </Modal>

                {/*<Modal
                    width={900}
                    visible={isUpdateModalVisible}
                    title="Modifier la Répartiton"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelUpdate}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateRepartition}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelUpdate}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codAgence">Agence</Label>
                                    <InputGroup compact>

                                        <Select
                                            value={codAgence}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Selectionner une Agence"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeClient}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                clients && clients.length ?
                                                    clients.map((agence, k) => {
                                                        return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.handleCancelAddClient} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantAp">Montant Commande</Label>
                                    <Input disabled type="number" name="montantAp" value={montantAp} id="montantAp" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8}>
                                <button className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Qté à Répartir</th>
                                            <th className="text-center">Qté Potentiel Stock</th>
                                            <th className="text-center">Qté Potentiel Restant</th>
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseInt(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{article.qtEntree}</td>
                                                            <td className="text-center">{parseInt(article.qteStock) - parseInt(article.qteStockEnCoursValidation) + parseInt(article.qtEntree)}</td>

                                                            <td className="text-center">{parseInt(article.qteStock) - parseInt(article.qteStockEnCoursValidation)}</td>

                                                            <Button className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Form>
                                    </Modal>*/}

                <Modal
                    width={900}
                    visible={isModalVisible}
                    title="Détails Facture"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="nomAgent">Agent</Label>
                                    <Input disabled type="text" name="nomAgent" value={nomAgent} onChange={this.handleChange} id="nomAgent" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="nomAgence">Agence</Label>
                                    <Input disabled type="text" name="nomAgence" id="nomAgence" value={nomAgence} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="refFact">Référence Devis</Label>
                                    <Input disabled type="text" name="refFact" value={refFact} onChange={this.handleChange} id="refFact" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateFact">Date</Label>
                                    <Input disabled type="text" name="dateFact" id="dateFact" value={dateFact/*moment(new Date(dateFact), 'DD/MM/YYYY').format('DD/MM/YYYY')*/} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codClient">Client</Label>
                                    <InputGroup compact>

                                        <Select
                                            disabled
                                            value={codClient}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Selectionner un Client"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeClient}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                clients && clients.length ?
                                                    clients.map((client, k) => {
                                                        return <Option value={client.codClient}>{client.raisonSociale}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button disabled onClick={this.handleCancelAddClient} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input disabled type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        {/*<Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="refFactFour">Facture Fournisseur N°</Label>
                                    <Input type="text" name="refFactFour" value={refFactFour} onChange={this.handleChange} id="refFactFour" placeholder="" />
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>*/}

                        <Row form>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="modRegl">Type règlement</Label>
                                    <Select disabled className="gx-mr-3 gx-mb-3" value={modRegl} onChange={this.handleChangeTypeReglement}>
                                        <Option value="Cash">Cash</Option>
                                        <Option value="Partiel">Partiel</Option>
                                        <Option value="Crédit">Crédit</Option>

                                    </Select>
                                    {//(modRegl === "A" || modRegl === "C" || modRegl === "E" || modRegl === "F") &&
                                        <h6 style={{ color: "black" }}><span>NB:Si la commande n'est pas payée à  l'avance, veuillez stipuler pour le Type de Règlement Crédit</span></h6>
                                    }

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codmod">Mode règlement</Label>
                                    <Select disabled className="gx-mr-3 gx-mb-3" value={codmod} onChange={this.handleChangeModReglem}>
                                        <Option value="AUTRE">Autre</Option>
                                        <Option value="CARTE">Carte Bancaire</Option>
                                        <Option value="CHEQUE">Chèque</Option>
                                        <Option value="ESPECES">Espèces</Option>
                                        <Option value="MOMO">Mobile Money</Option>
                                        <Option value="VIREMENT">Virement</Option>
                                    </Select>

                                </FormGroup>
                            </Col>

                        </Row>


                        <Row>
                            <Col md={8}>
                                <button disabled className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">PU Brut</th>
                                            <th className="text-center">Montant Remise</th>
                                            <th className="text-center">PU Net HT</th>
                                            <th className="text-center">Qté Commandée</th>
                                            <th className="text-center">Montant</th>

                                            <th className="text-center">Tva</th>
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseInt(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{numberWithCommas( parseInt(article.puBrut) )}</td>
                                                            <td className="text-center">{numberWithCommas( parseInt(article.puRemise) )}</td>
                                                            <td className="text-center">{numberWithCommas( parseInt(article.puUtilise) )}</td>
                                                            <td className="text-center">{parseInt(article.qtCommander)}</td>
                                                            <td className="text-center">{numberWithCommas( parseInt(article.puUtilise) * parseInt(article.qtCommander) )}</td>

                                                            <td className="text-center">{article.tauxTva}</td>

                                                            <Button disabled className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col md={5}>
                                <Card title="Groupe de Taxation et TVA" className="gx-card ">
                                    <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                        <thead className="bg-primary text-white">
                                            <tr>
                                                {/**<th className="text-center"> </th> */}

                                                <th className="text-center">Base</th>
                                                <th className="text-center">Taux(%)</th>
                                                <th className="text-center">Montant</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listeArticlesSelected && listeArticlesSelected.length ?
                                                    listeArticlesSelected.map((article, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                {/** <td className="text-center"><Input onChange={(e) => this.handleChangeCheck(e, article)} className="ml-1" type="checkbox" /></td> */}

                                                                <td className="text-center">{numberWithCommas( parseInt(article.puUtilise) * parseInt(article.qtCommander) )}</td>

                                                                <td className="text-center">{article.tauxTva}</td>

                                                                <td className="text-center">{numberWithCommas( (parseInt(article.puUtilise) * parseInt(article.qtCommander) * parseInt(article.tauxTva)) / 100 )}</td>



                                                            </tr>
                                                        );
                                                    })
                                                    : null
                                            }

                                        </tbody>
                                    </Table>
                                    <FormItem className="mt-3" label="Total TVA"  {...formItemLayout}>
                                        <Input disabled type="number" name="totalTva" value={totalTva} id="totalTva" onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>

                            </Col>
                            <Col md={7}>
                                <Card title="Totaux et Taxes" className="gx-card">
                                    <FormItem label="Net HT"  {...formItemLayout}>

                                        <Input disabled type="number" name="netHT" id="netHT" value={netHT} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    <FormItem label="Montant Remise"  {...formItemLayout}>

                                        <Input disabled type="number" name="montantRemise" id="montantRemise" value={montantRemise} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Taux Remise"  {...formItemLayout}>

                                        <Input disabled type="number" name="tauxRemise" id="tauxRemise" value={tauxRemise} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Net Commercial"  {...formItemLayout}>

                                        <Input disabled type="number" name="netCommercial" id="netCommercial" value={netCommercial} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Total TVA"  {...formItemLayout}>

                                        <Input disabled type="number" name="totalTva" value={totalTva} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    <FormItem label="Frais Transport"  {...formItemLayout}>

                                        <Input disabled type="number" name="fraisTransport" id="fraisTransport" value={fraisTransport} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Autres Frais"  {...formItemLayout}>

                                        <Input disabled type="number" name="refFact" id="autreFrais" value={autreFrais} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="Montant TTC"  {...formItemLayout}>

                                        <Input disabled type="number" name="montantTtc" id="montantTtc" value={montantTtc} onChange={this.handleChange} placeholder="" />
                                    </FormItem>

                                    <FormItem label="AIB"  {...formItemLayout}>

                                        <InputNumber name="tauxAib" id="tauxAib"
                                            disabled
                                            value={tauxAib}
                                            onChange={this.handleChangeAib}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            defaultValue={0}
                                            min={0}
                                            max={100} />
                                        <Input disabled type="number" name="montantAib" id="montantAib" value={montantAib} onChange={this.handleChange} placeholder="Montant AIB" />
                                    </FormItem>


                                    <FormItem label="Net à Payer"  {...formItemLayout}>

                                        <Input disabled type="number" name="netaPayer" id="netaPayer" value={netaPayer} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>


                            </Col>
                        </Row>

                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isAddClientModalVisible}
                    title="Ajouter un Client"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAddClient}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleAddClient}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelAddClient}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="raisonSociale">Raison Sociale</Label>
                                    <Input type="text" name="raisonSociale" id="raisonSociale" value={raisonSociale} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="ifu">IFU</Label>
                                    <Input type="number" name="ifu" value={ifu} id="ifu" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="gender">Sexe</Label>
                                    <Select className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                                        <Option value="M">Masculin</Option>
                                        <Option value="F">Feminin</Option>
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <Label for="dateNaissance">Date de Naissance</Label>
                                <FormGroup>

                                    <DatePicker style={{ width: '100%' }} name="dateNaissance" id="dateNaissance" value={moment(dateNaissance, dateFormatList[0])} locale={locale} onChange={this.handleChangeDate} format={dateFormatList} />
                                    {/*<Input type="date" name="dateNaissance" id="dateNaissance" value={dateNaissance} onChange={this.handleChange} placeholder="" />
                               */} </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="adresse">Adresse</Label>
                                    <Input type="text" name="adresse" value={adresse} onChange={this.handleChange} id="adresse" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="profession">Profession</Label>
                                    <Input type="text" name="profession" id="profession" value={profession} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact">Téléphone</Label>
                                    <Input type="text" name="contact" id="contact" value={contact} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact2">Téléphone 2</Label>
                                    <Input type="text" name="contact2" value={contact2} id="contact2" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="text" name="email" value={email} id="email" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="siteWeb">Site web</Label>
                                    <Input type="text" name="siteWeb" id="siteWeb" value={siteWeb} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="typeClient">Type de Client</Label>
                                    <Select
                                        value={typeClient}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner un Type"
                                        onChange={this.handleChangeTypeClient}
                                    >
                                        <Option value="CLIENT DETAILLANT">CLIENT DETAILLANT</Option>
                                        <Option value="CLIENT EN GROS">CLIENT EN GROS</Option>
                                        <Option value="CLIENT ORDINAIRE">CLIENT ORDINAIRE</Option>

                                    </Select>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="usuel">Usuel</Label>
                                    <Select

                                        value={usuel}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner une valeur"

                                        onChange={this.handleChangeUsuel}
                                    >
                                        <Option value="1">Usuel</Option>
                                        <Option value="0">Non Usuel</Option>
                                    </Select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <Label for="mentionClient">Mention Client</Label>
                                <FormGroup>

                                    <Radio.Group
                                    // options={optionsWithDisabled}
                                    // onChange={this.onChangeMention}
                                    //value={mentionClient}
                                    //optionType="button"
                                    //buttonStyle="solid"
                                    />
                                    <Radio.Group onChange={this.onChangeMention} value={mentionClient} defaultValue={mentionClient} buttonStyle="solid">
                                        <Radio.Button value="">Aucune option</Radio.Button>
                                        <Radio.Button value="NOUVEAU CLIENT">Nouveau Client</Radio.Button>
                                        <Radio.Button value="ANCIEN CLIENT">Ancien Client</Radio.Button>
                                    </Radio.Group>
                                </FormGroup>
                            </Col>
                        </Row>


                    </Form>
                </Modal>

                <Modal
                    width={700}
                    visible={isListArticleVisible}
                    title="Liste Produits"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openListArticleModal}
                    footer={[
                        /* <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateRepartition}>
                             Enrégistrer
                         </Button>,*/
                        <Button key="back" type="danger" onClick={this.openListArticleModal}>
                            Fermer
                        </Button>,

                    ]}
                >

                    <Row>
                        <Col md={12}>
                            <Input onChange={this.onRechercheArticleChange} type='text' placeholder='Rechercher' style={{ width: '100%' }} /> <hr />
                        </Col>
                    </Row>
                    <Row style={{ height: '300px', overflow: 'auto' }}>

                        <Col md={12}>
                            <ListGroup>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>
                                            <th className="text-center">Produits</th>
                                            <th className="text-center">Stock Actuel</th>
                                            {currentUser && currentUser.agence && currentUser.agence.enCours === true &&
                                                <th className="text-center">Stock En Validation</th>
                                            }
                                            {currentUser && currentUser.agence && currentUser.agence.enCours === true &&
                                                <th className="text-center">Stock Restant</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            articles && articles.length ?
                                                articles.map((article, k) => {
                                                    return article.article && article.article.designation &&
                                                        <tr key={k} id={k} onClick={(e) => this.openDetailArticleModal(article)} style={{ cursor: "pointer" }}>
                                                            <td className="text-center">{article.article ? article.article.designation : ''}</td>
                                                            <td className="text-center">{article.qteStock}</td>
                                                            {currentUser && currentUser.agence && currentUser.agence.enCours === true &&
                                                                <td className="text-center">{article.qteStockEnCoursValidation}</td>
                                                            }
                                                            {currentUser && currentUser.agence && currentUser.agence.enCours === true &&
                                                                <td className="text-center">{parseInt(article.qteStock) - parseInt(article.qteStockEnCoursValidation)}</td>
                                                            }
                                                        </tr>
                                                }) : null
                                        }

                                    </tbody>
                                </Table>

                            </ListGroup>

                        </Col>


                    </Row>
                </Modal>

                <Modal
                    width={500}
                    visible={isDetailsArticleVisible}
                    title="Complèter les détails pour cet Article"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openDetailArticleModal}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleArticleSelected}>
                            Ajouter
                        </Button>,
                        <Button key="back" type="danger" onClick={this.openDetailArticleModal}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="qteStock">Stok Potentiel Restant</Label>
                                    <Input disabled type="number" name="qteStock" value={parseInt(qteStock) - parseInt(qteStockEnCoursValidation)} onChange={this.handleChange} id="qteStock" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="qtCommander">Qté Commandée</Label>
                                    <Input type="number" name="qtCommander" id="qtCommander" value={qtCommander} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="puBrut">PU Brut</Label>
                                    <Input disabled type="number" name="puBrut" value={puBrut} onChange={this.handleChange} id="puBrut" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="puRemise">Montant Remise</Label>
                                    <Input type="number" name="puRemise" id="puRemise" value={puRemise} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>
                    </Form>
                </Modal>

                <Modal
                    width={400}
                    visible={isValidationValidation}
                    title="Validation Répartition"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCloseValidation}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleValiderRepartition}>
                            Valider
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCloseValidation}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <h5>
                        Voulez vous valider cette Répartition...?
                    </h5>
                </Modal>


            </div>
        )
    }
}


export default FacturesGlobale;
