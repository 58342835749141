import React from "react";
import { Card, Input, Button, Modal, Select, message, Popconfirm } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label,  } from 'reactstrap';
import Media from 'react-media';

import { services } from '../../../services/services'

//const InputGroup = Input.Group;

class Administrateurs extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        loading: false,
        isOpenDelete: false,
        agents: [],
        agences: [],
        gender: 'M',
        firstName: '',
        codAgence: '',
        lastName: '',

        profession: '',
        address: '',
        phone: '',

    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        services.getAdmin()
            .then(agents => {
               // console.log('agents', agents)
                this.setState({ agents })
            })

        /*  services.getAllAgences()
            .then(agences => {
              console.log('agences', agences)
              this.setState({ agences })
            })*/
    }

    handleAddOpen = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            gender: 'M'
        });
    };

    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codAgence: '',
            firstName: '',
            lastName: '',
            profession: '',
            address: '',
            phone: '',
            gender: '',
        })
    }

    handleAddAdmin = () => {
        const {
            //codAgence,
            firstName,
            lastName,
            profession,
            address,
            phone,
            gender,
        } = this.state


        if (firstName === '') {
            message.warning('Veuillez renseigner le champ Prénom', 5)
            return;
        }
        if (lastName === '') {
            message.warning('Veuillez renseigner le champ Nom', 5)
            return;
        }

        /*if (codAgence === '') {
          message.warning('Veuillez sélectionner une Agence', 5)
          return;
        }*/

        this.setState({ loading: true })

        let req = {
            //codAgence,
            firstName,
            lastName,
            profession,
            address,
            phone,
            gender,

        }
        services.addAdmin(req)
            .then(result => {
               // console.log(result)
                this.setState({
                    codAgence: '',
                    firstName: '',
                    lastName: '',
                    profession: '',
                    address: '',
                    phone: '',
                    gender: '',
                    loading: false,
                    isAddModalVisible: !this.state.isAddModalVisible,

                })
                message.success('Administrateur ajouter avec succès', 10)

                services.getAdmin()
                    .then(agents => {
                        this.setState({ agents })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de l\'ajout, Veuillez réessayer plus tard', 10)
            });
    }

    handleUpdateAgent = () => {
        const {
            idAgent,
            //codAgence,
            firstName,
            lastName,
            profession,
            address,
            phone,
            gender,
        } = this.state


        if (firstName === '') {
            message.warning('Veuillez renseigner le champ Prénom', 5)
            return;
        }
        if (lastName === '') {
            message.warning('Veuillez renseigner le champ Nom', 5)
            return;
        }

        /*if (codAgence === '') {
          message.warning('Veuillez sélectionner une Agence', 5)
          return;
        }*/

        let req = {
            // codAgence,
            firstName,
            lastName,
            profession,
            address,
            phone,
            gender,

        }
        console.log('REQ Update', req)
        services.updateAdmin(idAgent, req)
            .then(result => {
              //  console.log(result)
                this.setState({
                    idAgent: '',
                    codAgence: '',
                    firstName: '',
                    lastName: '',
                    profession: '',
                    address: '',
                    phone: '',
                    gender: '',
                    isUpdateModalVisible: !this.state.isUpdateModalVisible,

                })
                message.success('Administrateur modifier avec succès', 10)

                services.getAdmin()
                    .then(agents => {
                        this.setState({ agents })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
            });
    }

    handleDelete = (row) => {
      //  console.log(row)
        this.setState({
            idAgent: row.id,
            firstName: row.firstName,
            lastName: row.lastName,
            profession: row.profession,
            isOpenDelete: !this.state.isOpenDelete,
            statutCompte: row.statutCompte,
            user: row.user,
        })

    }

    handleDeleteAdmin = () => {

        const { idAgent } = this.state

        services.deleteAdmin(idAgent)
            .then(del => {
                //console.log('delete user', del)
                message.success('Administrateur supprimer avec succès', 10)
                services.getAdmin()
                    .then(agents => {
                        this.setState({
                            agents: agents,
                            isOpenDelete: !this.state.isOpenDelete
                        })
                    })

            })
            .catch((err) => {
                message.error(err, 10)
            });

    }

    handleUpdateOpen = (row) => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            idAgent: row.id,
            codAgence: row.agences,
            firstName: row.firstName,
            lastName: row.lastName,
            profession: row.profession,
            address: row.address,
            phone: row.phone,
            gender: row.gender,

        })

    }
    handleCancelUpdate = () => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            //codAgence: '',
            firstName: '',
            lastName: '',
            profession: '',
            address: '',
            phone: '',
            gender: '',
            idAgent: ''
        })
    }


    handleView = (row) => {
        this.setState({
            isModalVisible: !this.state.isModalVisible,
            codAgence: row.agences,
            firstName: row.firstName,
            lastName: row.lastName,
            profession: row.profession,
            address: row.address,
            phone: row.phone,
            gender: row.gender,

        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            // codAgence: '',
            firstName: '',
            lastName: '',
            profession: '',
            address: '',
            phone: '',
            gender: '',
        });
    }

    handleChangeGender = (value) => {
        this.setState({ gender: value })
    }

    handleChangeAgence = (value) => {
        this.setState({ codAgence: value })
    }

    OpenModalAddAgence = () => {
        this.setState({ IsModalAddAgence: !this.state.IsModalAddAgence })
    }


    handleAddAgence = () => {
        const {
            //codAgence,
            codificationAgence,
            nomAgence,
            adrAgence,
            telAgence,
            nomGerant,
            telGerant,
            observation,
        } = this.state


        if (nomAgence === '') {
            message.warning('Veuillez préciser le nom de l\'Agence', 5)
            return;
        }
        /*if (codAgence === '') {
          message.warning('Veuillez sélectionner une Agence', 5)
          return;
        }
    */
        let req = {
            //codAgence,
            codificationAgence,
            nomAgence: nomAgence.toUpperCase(),
            adrAgence,
            telAgence,
            nomGerant,
            telGerant,
            observation,
            //raisonSociale: raisonSociale.toUpperCase(),

        }
        services.addAgence(req)
            .then(result => {
              //  console.log(result)
                this.setState({
                    //codAgence: '',
                    codificationAgence: '',
                    nomAgence: '',
                    adrAgence: '',
                    telAgence: '',
                    nomGerant: '',
                    telGerant: '',
                    observation: '',
                    IsModalAddAgence: !this.state.IsModalAddAgence,

                })
                message.success('Agences ajouter avec succès', 6)

                services.getAllAgences()
                    .then(agences => {
                        this.setState({ agences })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de l\'ajout, Veuillez réessayer plus tard', 10)
            });
    }

    handleDeleteAdminOpen = () => {
        this.setState({ isOpenDelete: !this.state.isOpenDelete })
    }


    disableUser = () => {

        const { user } = this.state

        services.disableUser(user)
            .then(del => {
                //console.log('disable user', del)
                this.setState({
                    isOpenDelete: !this.state.isOpenDelete
                })

                message.success('Agents Désactiver avec succès', 6)
                services.getAdmin()
                    .then(agents => {
                        this.setState({ agents })
                    })

            })
            .catch(() => {
                message.error('Une erreur est survenue lors de la Désactivation, Veuillez réessayer plus tard', 10)
            });

    }

    enableUser = () => {

        const { user } = this.state

        services.enableUser(user)
            .then(del => {
                //console.log('delete user', del)
                this.setState({
                    isOpenDelete: !this.state.isOpenDelete
                })

                message.success('Agent Activer avec succès', 6)
                services.getAdmin()
                    .then(agents => {
                        this.setState({ agents })
                    })

            })
            .catch(() => {
                message.error('Une erreur est survenue lors de l\'Activation, Veuillez réessayer plus tard', 10)
            });

    }

    handleChangeAgence = (value) => {
        this.setState({ codAgenceChange: value })
        services.getAdmin(value)
            .then(agents => {
                //console.log('agents', agents)
                this.setState({ agents })
            })
    }


    render() {
        const {
            isModalVisible,
            loading,
            //agences,

            //codAgence,
            //codAgenceChange,
            firstName,
            lastName,
            profession,
            address,
            phone,
            gender,

            agents,


            codificationAgence,
            nomAgence,
            adrAgence,
            telAgence,
            nomGerant,
            telGerant,
            observation,

            statutCompte,

            isOpenDelete,
            isAddModalVisible,
            isUpdateModalVisible,
            IsModalAddAgence
        } = this.state


        const Option = Select.Option;

        const { SearchBar } = Search;

        const columnsAgents = [{
            dataField: 'id',
            text: ' ID'
        },
        {
            dataField: 'username',
            text: 'Utilisateur',
            style: (cell, row, rowIndex, colIndex) => {
                if (row.statutCompte === false) {
                    return {
                        backgroundColor: '#F08080'
                    };
                }
            }
        },
        {
            dataField: 'firstName',
            text: ' Prénom',
            style: (cell, row, rowIndex, colIndex) => {
                if (row.statutCompte === false) {
                    return {
                        backgroundColor: '#F08080'
                    };
                }
            }
        },
        {
            dataField: 'lastName',
            text: ' Nom ',
            style: (cell, row, rowIndex, colIndex) => {
                if (row.statutCompte === false) {
                    return {
                        backgroundColor: '#F08080'
                    };
                }
            }
        },
        {
            dataField: 'phone',
            text: 'Téléphone'
        },

        {
            dataField: 'password',
            text: ' Password',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        {row && row.passwordUpdated === false &&
                            row.password}
                        {row && row.passwordUpdated === true &&
                            <p style={{ textAlign: "center" }} className="d-flex">****</p>}
                    </div>
                );
            },

        },

        {
            dataField: '',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        <Button
                            type="primary"
                            //fullWidth
                            variant="contained"
                            color="primary"
                            size="sm"
                            //className="btn btn-success btn-sm"
                            onClick={() => this.handleView(row)}
                        >
                            <i className="icon icon-custom-view" />
                        </Button>
                        <Button
                            type="primary"
                            //fullWidth
                            variant="contained"
                            color="danger"
                            size="sm"
                            //className="btn btn-success btn-sm"
                            onClick={() => this.handleUpdateOpen(row)}
                        >
                            <i className="icon icon-edit" />
                        </Button>
                        {/*<Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Agent?" onConfirm={this.handleDeleteAdmin} onCancel={this.cancelDelete} okText="Oui"
              cancelText="Non">*/}
                        <Button
                            type="danger"
                            //fullWidth
                            variant="contained"
                            color="primary"
                            size="sm"
                            //className="btn btn-success btn-sm"
                            onClick={() => this.handleDelete(row)}
                        >
                            <i className="icon icon-alert-new" />
                        </Button>

                        {/*</Popconfirm>*/}

                    </div>
                );
            },

        }

        ];


        const columnsAgentsPhone = [

            {
                dataField: 'username',
                text: 'Utilisateur'
            },
            {
                dataField: 'firstName',
                text: ' Prénom',
                style: (cell, row, rowIndex, colIndex) => {
                    if (row.statutCompte === false) {
                        return {
                            backgroundColor: '#F08080'
                        };
                    }
                }
            },
            {
                dataField: 'lastName',
                text: ' Nom ',
                style: (cell, row, rowIndex, colIndex) => {
                    if (row.statutCompte === false) {
                        return {
                            backgroundColor: '#F08080'
                        };
                    }
                }
            },


            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="sm"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                            <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer ce Fournisseur?" onConfirm={this.handleDeleteAdmin} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                                </Button>

                            </Popconfirm>

                        </div>
                    );
                },

            }

        ];

        const defaultSorted = [{
            dataField: 'firstName',
            order: 'asc'
        }];



        return (
            <div>
                <Card title="Liste des Administrateurs">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={agents}
                            columns={columnsAgents}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={9} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 650, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>



                                            {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                                                <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>

                                            }
                                        </Row>


                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={agents}
                            columns={columnsAgentsPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                <i className="fa fa-search" style={{ margin: 10 }}></i>

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>



                                            {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                                                <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>
                                            }

                                        </Row>

                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>


                <Modal
                    width={800}
                    visible={isAddModalVisible}
                    title="Ajouter un Administrateur"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAdd}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleAddAdmin}>
                            Envoyer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelAdd}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName">Prénom</Label>
                                    <Input type="text" name="firstName" id="firstName" value={firstName} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName">Nom</Label>
                                    <Input type="text" name="lastName" value={lastName} id="lastName" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="gender">Sexe</Label>
                                    <Select className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                                        <Option value="M">Masculin</Option>
                                        <Option value="F">Feminin</Option>
                                    </Select>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="profession">Poste</Label>
                                    <Input type="text" name="profession" value={profession} onChange={this.handleChange} id="profession" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="address">Adresse</Label>
                                    <Input type="text" name="address" id="address" value={address} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="phone">Téléphone</Label>
                                    <Input type="text" name="phone" value={phone} id="phone" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* <Row form>

              <Col md={12}>
                <FormGroup>
                  <Label for="codAgence">Agences</Label>
                  <InputGroup compact>

                    <Select

                      value={codAgence}
                      showSearch
                      //style={{width: 200}}
                      style={{ width: '85%' }}
                      placeholder="Selectionner Agence"
                      optionFilterProp="children"
                      onChange={this.handleChangeAgence}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        agences && agences.length ?
                          agences.map((agence, k) => {
                            return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                          }) : null
                      }

                    </Select>
                    <Button onClick={this.OpenModalAddAgence} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                  </InputGroup>
                </FormGroup>

              </Col>
            </Row>*/}

                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isUpdateModalVisible}
                    title="Modifier l'Administrateur"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelUpdate}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleUpdateAgent}>
                            Modifier
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleCancelUpdate}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName">Prénom</Label>
                                    <Input type="text" name="firstName" id="firstName" value={firstName} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName">Nom</Label>
                                    <Input type="text" name="lastName" value={lastName} id="lastName" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="gender">Sexe</Label>
                                    <Select className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                                        <Option value="M">Masculin</Option>
                                        <Option value="F">Feminin</Option>
                                    </Select>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="profession">Poste</Label>
                                    <Input type="text" name="profession" value={profession} onChange={this.handleChange} id="profession" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="address">Adresse</Label>
                                    <Input type="text" name="address" id="address" value={address} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="phone">Téléphone</Label>
                                    <Input type="text" name="phone" value={phone} id="phone" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isModalVisible}
                    title="Détails Administrateur"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName">Prénom</Label>
                                    <Input disabled type="text" name="firstName" id="firstName" value={firstName} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName">Nom</Label>
                                    <Input disabled type="text" name="lastName" value={lastName} id="lastName" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="gender">Sexe</Label>
                                    <Select disabled className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                                        <Option value="M">Masculin</Option>
                                        <Option value="F">Feminin</Option>
                                    </Select>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="profession">Poste</Label>
                                    <Input disabled type="text" name="profession" value={profession} onChange={this.handleChange} id="profession" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="address">Adresse</Label>
                                    <Input disabled type="text" name="address" id="address" value={address} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="phone">Téléphone</Label>
                                    <Input disabled type="text" name="phone" value={phone} id="phone" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                    </Form>
                </Modal>

                <Modal
                    width={600}
                    visible={IsModalAddAgence}
                    title="Ajouter une Agence"
                    //onOk={this.handleAddOpen}
                    onCancel={this.OpenModalAddAgence}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleAddAgence}>
                            Enrégistrer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.OpenModalAddAgence}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            {/*<Col md={6}>
                <FormGroup>
                  <Label for="codAgence">Code Agence</Label>
                  <Input type="number" name="codAgence" id="codAgence" value={codAgence} onChange={this.handleChange} placeholder="" />
                </FormGroup>
        </Col>*/}
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="codificationAgence">Codification</Label>
                                    <Input type="text" name="codificationAgence" value={codificationAgence} id="codificationAgence" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="nomAgence">Nom Agence</Label>
                                    <Input type="text" name="nomAgence" value={nomAgence} onChange={this.handleChange} id="nomAgence" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="adrAgence">Adresse</Label>
                                    <Input type="text" name="adrAgence" id="adrAgence" value={adrAgence} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="telAgence">Téléphone</Label>
                                    <Input type="number" name="telAgence" value={telAgence} id="telAgence" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup >
                                    <Label for="nomGerant">Nom Gérant</Label>
                                    <Input type="text" name="nomGerant" value={nomGerant} id="nomGerant" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="telGerant">Téléphone Gérant</Label>
                                    <Input type="number" name="telGerant" value={telGerant} id="telGerant" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="observation">Observation</Label>
                            <Input type="text" name="observation" value={observation} id="observation" onChange={this.handleChange} placeholder="" />
                        </FormGroup>

                    </Form>
                </Modal>


                <Modal
                    width={400}
                    visible={isOpenDelete}
                    title="Suppression Administrateur"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCloseValidation}
                    footer={[

                        statutCompte === false &&
                        <button className="btn btn-success mt-3 " onClick={this.enableUser} variant="contained">Activer</button>,

                        statutCompte === true &&
                        <button className="btn btn-info mt-3 " onClick={this.disableUser} variant="contained">Désactiver</button>,



                        <Button key="submit" type="primary" loading={loading} onClick={this.handleDeleteAdmin}>
                            Supprimer
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handleDeleteAdminOpen}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <h5>
                        Voulez vous Désactiver/Supprimer l'Agent {firstName} {lastName}?
                    </h5>
                </Modal>


            </div>
        )
    }
}


export default Administrateurs;
