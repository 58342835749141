import React from "react";
import { Card,   } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Spinner,  } from 'reactstrap';
import Media from 'react-media';


import { services } from '../../../services/services'
//import { use_logiciel } from '../../../env'


//const confirm = Modal.confirm;

//const FormItem = Form.Item;

//const moment = require('moment')

class StockAgence extends React.Component {
    state = {
        stocksAgences: [],
        waitingEndMsg: '',
        isLoading: true,
    }


    componentDidMount() {
        services.getStocksAgence()
            .then(stocksAgences => {
                //console.log('stocksAgences', stocksAgences)
                this.setState({ stocksAgences })

                setTimeout(() => {

                    //console.log("real articles", stocksAgences)
                    if (!stocksAgences || !Array.isArray(stocksAgences)
                        || !stocksAgences.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez pas de Stock actuellement !" })
                    }
                }, 500)

            })

    }



    render() {
        const {
            stocksAgences,

            isLoading,
            waitingEndMsg,

        } = this.state


     //   const InputGroup = Input.Group;
     //   const Option = Select.Option;

        const { SearchBar } = Search;



        const columnsStocksAgences = [{
            dataField: 'id',
            text: ' ID',
            headerAttrs: { width: 50 },
        },
        {
            dataField: 'designation',
            text: 'Article',
            headerAttrs: { width: 350 },
            sort: true,
        },
        /*{
            dataField: 'agence.nomAgence',
            text: 'Agence'
         },*/

        {
            dataField: 'qteStock',
            text: 'Stock',
            headerAttrs: { width: 100 },
        },

            /*{
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="large"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                            <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Approvisionnement?" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                                </Button>
    
                            </Popconfirm>
    
                            <Button
                                type="danger"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleValider(row)}
                            >
                                <i className="icon icon-check-circle-o" />
                            </Button>
    
                        </div>
                    );
                },
    
            }*/

        ];


        const columnsStocksAgencesPhone = [

            {
                dataField: 'designation',
                text: 'Article',
                sort: true,
            },
            /*{
                dataField: 'agence.nomAgence',
                text: 'Agence'
            },*/

            {
                dataField: 'qteStock',
                text: 'Stock'
            },
            /*{
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="large"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                            <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Approvisionnement?" onConfirm={this.handleDeleteApprovisionnement} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                                </Button>

                            </Popconfirm>

                        </div>
                    );
                },

            }*/

        ];

        const defaultSorted = [{
            dataField: 'designation',
            order: 'asc'
        }];


        return (
            <div>
                <Card title="Stocks Agence">

                    <Media query="(min-width: 600px)" render={() =>
                    (


                        <ToolkitProvider
                            keyField="id"
                            data={stocksAgences}
                            columns={columnsStocksAgences}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 950, width: '200%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>

                                        </Row>

                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }

                                        {
                                            stocksAgences &&
                                                Array.isArray(stocksAgences) &&
                                                stocksAgences.length > 0 ?
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    //bootstrap4
                                                    wrapperClasses="table-responsive"
                                                    //selectRow={ selectRow }
                                                    striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"


                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                //filter={filterFactory()}
                                                />

                                                :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={stocksAgences}
                            columns={columnsStocksAgencesPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                <i className="fa fa-search" style={{ margin: 10 }}></i>

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>

                                        </Row>

                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"


                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>

            </div>
        )
    }
}


export default StockAgence;
