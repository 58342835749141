import React from "react";
import {  Form, Input, Button, Modal, Select, message, } from "antd";


import { Col, Row } from "antd";
import ContainerHeader from "components/ContainerHeader/index";
//import IntlMessages from "util/IntlMessages";

import { FormGroup, Label, } from 'reactstrap';


import { services } from '../../services/services'

//const confirm = Modal.confirm;

//const FormItem = Form.Item;


class Profile extends React.Component {
    state = {
        userInfo: '',
        handlOpenPassword: false,
        isOpenNewPassword: false,
        password:'',
        newPassword: '',
        confirmPassword:'',
        codAgence: '',
    }


    componentDidMount() {
        services.getCurrentUser()
            .then(userInfo => {
                //console.log('userInfo', userInfo)
                this.setState({
                    userInfo: userInfo,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    profession: userInfo.groupe || '',
                    address: userInfo.address || '',
                    phone: userInfo.contactAgent || '',
                    gender: userInfo.gender || '',
                    nomAgence: userInfo && userInfo.agence.nomAgence,
                    role: userInfo.slug.toUpperCase(),
                    userId: userInfo.id,
                    username: userInfo.username,
                    codAgence: userInfo && userInfo.agence && userInfo.agence.codAgence,
                })
            })
    }


    handleUpdateUser = () => {
        const {
            userId,
            firstName,
            lastName,
            profession,
            address,
            phone,
            gender,
            role,
            codAgence,
        } = this.state


        if (firstName === '') {
            message.warning('Veuillez renseigner le champ Prénom', 5)
            return;
        }
        if (lastName === '') {
            message.warning('Veuillez renseigner le champ Nom', 5)
            return;
        }


        let req = {
            codAgence,
            prenomAgent: firstName,
            nomAgent: lastName,
            contactAgent: phone,

            firstName,
            lastName,
            profession,
            address,
            phone,
            gender,
            role,

        }
        //console.log('REQ Update', req)
        services.updateUser(userId, req)
            .then(result => {
                //console.log(result)
                this.setState({
                    userId: '',
                    //codAgence: '',
                    firstName: '',
                    lastName: '',
                    profession: '',
                    address: '',
                    phone: '',
                    gender: '',
                    // isUpdateModalVisible: !this.state.isUpdateModalVisible,

                })
                message.success('Utilisateur modifier avec succès', 10)

                services.getCurrentUser()
                    .then(userInfo => {
                        //console.log('userInfo', userInfo)
                        this.setState({
                            userInfo: userInfo,
                            firstName: userInfo.firstName,
                            lastName: userInfo.lastName,
                            profession: userInfo.groupe,
                            address: userInfo.address,
                            phone: userInfo.contactAgent,
                            gender: userInfo.gender,
                            nomAgence: userInfo && userInfo.agence.nomAgence,
                            role: userInfo.slug.toUpperCase(),
                            userId: userInfo.id,
                            username: userInfo.username,
                        })
                    })

            })

            .catch((err) => {
                message.error(err, 10)
            });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeGender = (value) => {
        this.setState({ gender: value })
    }

    handlOpenPassword = () => {
        this.setState({ isOpenPassword: !this.state.isOpenPassword })
    }

    handleOpenNewPassword= () => {
        this.setState( {isOpenNewPassword : !this.state.isOpenNewPassword})
    }


    updatePassword = () => {
        const {
          password,
          newPassword,
          confirmPassword,
          
        } = this.state
    
        if (password === '') {
            message.warning('Veuillez préciser votre Actuel Mot de Passe', 7)
          return;
        }

        if (newPassword === '') {
            message.warning('Veuillez préciser un nouveau Mot de Passe', 7)
          return;
        }

        if (confirmPassword === '') {
            message.warning('Veuillez confirmer le Mot de Passe', 7)
          return;
        }

        if (newPassword !== confirmPassword) {
            message.warning('Le Nouveau mot de passe ne correspond pas au Mot de passe de confirmation', 7)
          return;
        }
        
        ////console.log(this.state)
        let req = {
          password,
          newPassword,
          
    
        }
        services.updatePassword(req)
          .then(result => {
            //console.log(result)
            this.setState({isOpenPassword: !this.state.isOpenPassword, password:"", newPassword:""})
            message.success('Mot de passe modifier avec succès', 10)
            
          })
    
          .catch((err) => {
              //console.log('err', err)
            message.error(err, 7)
          });
    
      }

    render() {
        const {

            //codAgence,
           // userInfo,
            firstName,
            lastName,
            profession,
            address,
            phone,
            gender,
            nomAgence,
            role,
            username,
            isOpenPassword,
            password,
            newPassword,
            confirmPassword,
            //isOpenNewPassword,

        } = this.state


        //const InputGroup = Input.Group;
        const Option = Select.Option;



        return (
            <div className="gx-main-content gx-pb-sm-4">
                <Row>
                    <Col span={24}>
                        <ContainerHeader title="Profil" />
                    </Col>
                    <Col span={24}>
                        <div className={`gx-user-list gx-card-list`}>
                            <img alt="..." src={require("assets/images/User.png")} className="gx-avatar-img gx-border-0" />
                            <div className="gx-description">
                                <div className="gx-flex-row">
                                    <h4>{firstName} {lastName}</h4>
                                    <span className="gx-d-inline-block gx-toolbar-separator">&nbsp;</span>
                                    <span> {role}</span>
                                </div>
                                <p className="gx-text-grey gx-mb-2"> {profession}/{nomAgence}  </p>
                                <p>
                                    <span className="gx-mr-3">Téléphone:<span className="gx-text-grey"> {phone}</span></span>
                                    <span className="gx-mr-3">Adresse: <span className="gx-text-grey">{address}</span></span>
                                </p>

                                <Form className="mt-4">
                                    <Row form>
                                        <Col md={12}>

                                            <Label for="firstName">Prénom</Label>
                                            <Input type="text" name="firstName" id="firstName" value={firstName} onChange={this.handleChange} placeholder="" />

                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="lastName">Nom</Label>
                                                <Input type="text" name="lastName" value={lastName} id="lastName" onChange={this.handleChange} placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup >
                                                <Label for="gender">Sexe</Label>
                                                <Select className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                                                    <Option value="M">Masculin</Option>
                                                    <Option value="F">Feminin</Option>
                                                </Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="profession">Groupe</Label>
                                                <Input disabled type="text" name="profession" value={profession} onChange={this.handleChange} id="profession" placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>



                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="address">Adresse</Label>
                                                <Input disabled type="text" name="address" id="address" value={address} onChange={this.handleChange} placeholder="" />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="phone">Téléphone</Label>
                                                <Input type="text" name="phone" value={phone} id="phone" onChange={this.handleChange} placeholder="" />
                                            </FormGroup>
                                        </Col>
                                    </Row>



                                </Form>

                            </div>
                            <div className="gx-card-list-footer">
                                <Button onClick={this.handleUpdateUser} type="primary">Modifier</Button>
                                <Button onClick={this.handlOpenPassword}>Mot de Passe</Button>
                            </div>
                        </div>
                    </Col>
                </Row>

               

                <Modal
                    width={400}
                    visible={isOpenPassword}
                    title="Modifier Mot de passe"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handlOpenPassword}
                    footer={[


                        <Button key="submit" type="primary" onClick={this.updatePassword}>
                            Modifier
                        </Button>,
                        <Button key="back" type="danger" onClick={this.handlOpenPassword}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                    <Row form>
                        <Col md={24}>
                                <FormGroup>
                                    <Label for="username"> Utilisateur</Label>
                                    <Input disabled type="text" name="username" id="username" value={username} onChange={this.handleChange}  />
                                </FormGroup>
                            </Col>

                            <Col md={24}>
                                <FormGroup>
                                    <Label for="password"> Actuel Mot de passe</Label>
                                    <Input type="password" name="password" id="password" value={password} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            
                        </Row>
                        <Row form>
                            <Col md={24}>
                                <FormGroup>
                                    <Label for="newPassword">Nouveau Mot de passe</Label>
                                    <Input type="password" name="newPassword" id="newPassword" value={newPassword} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={24}>
                                <FormGroup>
                                    <Label for="confirmPassword">Confirmer Mot de passe</Label>
                                    <Input type="password" name="confirmPassword" id="confirmPassword" value={confirmPassword} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                    </Form>

                </Modal>
            </div>
        )
    }
}


export default Profile;
