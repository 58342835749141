import React from "react";
import { Card, DatePicker,  } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col,  Button, Table, Spinner, Modal, ModalBody, ModalHeader, ModalFooter, CardBody, FormGroup } from 'reactstrap';
import Media from 'react-media';

//import filterFactory from 'react-bootstrap-table2-filter';
//import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import 'moment/locale/fr-ca';
//import locale from 'antd/es/date-picker/locale/fr_FR';

import { services } from '../../../services/services';
//import { authenticationService } from "../../../services/authentication-service";


import { numberWithCommas } from '../../../helpers/index';
import { img_base_url } from '../../../env';
import productImg from "../../../assets/images/menu/djewp.jpg"
//import fr from 'date-fns/locale/fr';
//import DatePicker from "react-datepicker";
//import { use_logiciel } from '../../../env'




//const currentUser = authenticationService.currentUserValue

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

//const confirm = Modal.confirm;

//Récuppérer le premier et le dernier jour du mois 
//const dateTime = new Date(), y = dateTime.getFullYear(), m = dateTime.getMonth();
//const firstDay = new Date(y, m, 1);
//const lastDay = new Date(y, m + 1, 0);

//const FormItem = Form.Item;

const moment = require('moment')

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

class CmdPointsDetails extends React.Component {
    state = {
        open: false,
        listeArticlesSelected: [],


        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        loading: false,
        isListArticleVisible: false,
        isDetailsArticleVisible: false,
        isValidationValidation: false,
        repartitions: [],
        //listeArticlesSelected: [],
        articles: [],
        articlesCodPro: [],
        agences: [],
        formLayout: 'horizontal',

        usuel: "1",
        codTyp: "1",
        libCat: '',
        codeCat: '',
        emailFour: '',
        refFactFour: '',
        codAgence: '',
        modRegl: '',
        codmod: '',
        tauxAib: 0,
        fraisTransport: 0,
        cleRepartition: 0,
        dateFact: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        dateEnCours: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        //codAgenceChange: '',
        etatCmde: 'EN COURS',
        //puRemise: 0,
        autreFrais: 0,
        montantRemise: 0,
        puRemise: 0,
        qtCommander: 0,

        factures: [],
        clients: [],
        //agences: [],
        gender: 'M',
        raisonSociale: '',
        codClient: '',
        mentionClient: 'NOUVEAU CLIENT',
        typeClient: 'CLIENT ORDINAIRE',
        //usuel: '1',
        dateNaissance: moment('01/01/2021', dateFormatList[0]),
        ifu: '',

        waitingEndMsg: '',
        isLoading: true,


        //listeArticlesSelected: [],
        commandes: [],
        clientListeArticlesSelected: [],
        commande: '',
        //startDate: firstDay,
        //endDate: lastDay,
        startDate: moment(),
        endDate: moment(),

    }


    handleChangeStartDate = (date) => {
        ////console.log('DATE START', moment(new Date(date), 'DD/MM/YYYY').format('YYYY-MM-DD'))
        this.setState({ startDate: date, loading: true, emptyData: false });

        const startDate = moment(new Date(date), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(this.state.endDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        ////console.log('START DATE', startDate, endDate)
        services.getHistoriqueCommande(startDate, endDate)
            .then(commandes => {
                //console.log('commandes', commandes)
                if (commandes.length > 0) {
                    this.setState({ commandes: commandes, loading: false })

                } else {
                    this.setState({ emptyData: true, commandes: [] })
                }

            })
    };

    handleChangeEndDate = (date) => {
        this.setState({ endDate: date, loading: true, emptyData: false });

        const startDate = moment(new Date(this.state.startDate), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(date), 'DD/MM/YYYY').format('YYYY-MM-DD')
        ////console.log('START DATE', startDate, endDate)
        services.getHistoriqueCommande(startDate, endDate)
            .then(commandes => {
                //console.log('commandes', commandes)
                this.setState({ commandes: commandes })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                    if (!commandes || !Array.isArray(commandes)
                        || !commandes.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Commande pour la date du ${this.state.dateEnCours}!` })
                    }
                }, 500)

            })
    };


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {

        const startDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        ////console.log('PREMIER ET DERNIER JOUR MOIS', dateEnCours)
        /* services.getHistoriqueCommande(startDate, endDate)
             .then(commandes => {
                 //console.log('commandes', commandes)
                 this.setState({
                     commandes: commandes,
                 })
                 setTimeout(() => {
 
                     // //console.log("real articles", factures)
                     if (!commandes || !Array.isArray(commandes)
                         || !commandes.length > 0) {
                         this.setState({ isLoading: false, waitingEndMsg: `Vous n\'avez aucune Commande pour la date du ${this.state.dateEnCours}!` })
                     }
                 }, 2000)
             })*/

        services.getPointDuJour(startDate, endDate)
            .then(commandes => {
                //console.log('commandesAct', commandes)
                
                    this.setState({ commandes: commandes, loading: false })

                    setTimeout(() => {
                        // //console.log("real articles", factures)
                        if (!commandes || !Array.isArray(commandes)
                            || !commandes.length > 0) {
                            this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Commande pour la date du ${this.state.dateEnCours}!` })
                        }
                    }, 500)

               
            })

        services.getCategories()
            .then(categories => {
                // //console.log('categories', categories)
                this.setState({ categories })
            })

    }

    onDateChange = (date, dateString) => {
        // //console.log("DATE", date, dateString, dateString[0]);
        this.setState({ isLoading: true, factures: [] })

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
        //  //console.log("DATE CHANGE PERIODE", dateString[0], dateString[1], this.state.dateEnCours, this.state.codAgence, startDate, endDate)

        services.getPointDuJour(startDate, endDate)
            .then(commandes => {
                //console.log('commandesAct', commandes)
                
                    this.setState({ commandes: commandes, loading: false })

                    setTimeout(() => {
                        // //console.log("real articles", factures)
                        if (!commandes || !Array.isArray(commandes)
                            || !commandes.length > 0) {
                            this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Commande pour la période définie  !` })
                        }
                    }, 2000)

                
            })

        this.setState({ startDate: dateString[0], endDate: dateString[1] })


    }


    handleView = (commande) => {

        this.setState({ commande: commande, clientListeArticlesSelected: commande.sousCommandes/*listGrouper.filter(x => x !== [])*/, listeArticlesSelected: commande.listeArticlesSelected, isModalVisible: !this.state.isModalVisible })

        /*this.setState({
            isModalVisible: !this.state.isModalVisible,

            numFact: row.numFact,
            dateFact: row.dateFact,
            codClient: row.codClient,
            observation: row.observation,
            modRegl: row.modRegl,
            codmod: row.codmod,
            montantRemise: row.montantRemise,
            fraisTransport: row.fraisTransport,
            autreFrais: row.autreFrais,
            tauxAib: row.tauxAib,
            totalTva: row.totalTva,
            netHT: row.netHT,
            tauxRemise: row.tauxRemise,
            netCommercial: row.netCommercial,
            montantTtc: row.montantTtc,
            montantAib: row.montantAib,
            netaPayer: row.netaPayer,
            montFact: row.montFact,
            refFact: row.refFact,
            nomAgence: row && row.agences ? row.agences.nomAgence : '',
            nomAgent: `${row && row.userInfo ? row.userInfo.firstName : ''} ${row && row.userInfo ? row.userInfo.lastName : ''}`,

            //codAgence: `${row.codAgence}`,
            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        //RESTE CETTE PARTIE AVEC LES AGENTS
                        //numFact: facture.id,

                        puRemise: details.puRemise,
                        qtCommander: details.qtCommander,
                        codTva: details.codTva,
                        tauxTva: details.tauxTva,
                        puAchat: details.puAchat,
                        puBrut: details.puBrut,
                        puRemise: details.puRemise,
                        puUtilise: details.puUtilise,
                        codAgence: details.codAgence,
                        //codProd: details.codProd,

                        //qtEntree: details.qtEntree,
                        //qteStock: details && details.stock ? details.stock.qteStock : '',
                        //qteStockEnCoursValidation: details && details.stock ? details.stock.qteStockEnCoursValidation : '',
                        designation: details && details.article ? details.article.designation : '',
                        codProd: details && details.article ? details.article.codPro : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.article ? details.article.codPro : ''
                }) : [],

        })*/
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            dateRepartition: '',
            observation: '',
            codAgence: '',

            listeArticlesSelected: [],
        });
    }


    handleChangeGender = (value) => {
        this.setState({ gender: value })
    }


    onChangeMention = e => {
        ////console.log('radio4 checked', e.target.value);
        this.setState({
            mentionClient: e.target.value,
        });
    };

    handleChangeUsuel = (value) => {
        this.setState({ usuel: value })
    }

    handleChangeDate = (date, dateString) => {
        //console.log(dateString, moment(dateString).format('DD/MM/YYYY'), moment(date, dateFormatList[0]));
        this.setState({ dateNaissance: moment(dateString).format('DD/MM/YYYY') })
    }

    handleChangeTypeClient = (value) => {
        this.setState({ typeClient: value })
    }
    //FIN FOURNISSEUR


    handleChangeEtatCmde = (value) => {
        this.setState({ etatCmde: value });
        services.getFacturesGlobale(value, this.state.dateEnCours)
            .then(factures => {
                // //console.log('factures', repartitions)
                this.setState({ factures })
            })

    }

    handleChangeAgence = (value) => {
        this.setState({ codAgenceChange: value })
        this.setState({ isLoading: true, waitingEndMsg: '' })
        services.getFacturesGlobale(value, this.state.dateEnCours)
            .then(factures => {
                //console.log('factures', factures)
                this.setState({ factures })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                    if (!factures || !Array.isArray(factures)
                        || !factures.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Facture actuellement pour cette Agence!" })
                    }
                }, 500)
            })
    }

    handleChangeDateFiltre = (date, dateString) => {
        ////console.log(dateString, moment(dateString).format('DD/MM/YYYY'), moment(date, dateFormatList[0]));
        this.setState({ dateEnCours: dateString })
        this.setState({ isLoading: true, waitingEndMsg: '' })

        const startDate = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
        //const endDate = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
        //console.log('PREMIER ET DERNIER JOUR MOIS', dateString, startDate,)


        services.getPointDuJour('', '', '', startDate)
            .then(commandes => {
                //console.log('commandesAct', commandes)
                if (commandes.length > 0) {
                    this.setState({ commandes: commandes, loading: false })

                    setTimeout(() => {
                        // //console.log("real articles", factures)
                        if (!commandes || !Array.isArray(commandes)
                            || !commandes.length > 0) {
                            this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Commande pour la date du ${this.state.dateEnCours}!` })
                        }
                    }, 2000)

                } else {
                    this.setState({ commandes: [], isLoading: false, emptyData: true, waitingEndMsg: `Vous n'avez aucune Commande pour la date du ${this.state.dateEnCours}!` })
                }
            })

        /*services.getHistoriqueCommande(startDate, endDate)
            .then(commandes => {
                //console.log('commandes', commandes)
                this.setState({
                    commandes: commandes,
                })
                setTimeout(() => {

                    // //console.log("real articles", factures)
                    if (!commandes || !Array.isArray(commandes)
                        || !commandes.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n\'avez aucune Commande pour la date du ${this.state.dateEnCours}!` })
                    }
                }, 2000)
            })*/
    }

    handleDetailsCommande = (row) => {
        this.setState({ commande: row, listeArticlesSelected: row.detailsCommande, open: true })
    }
    onCloseModalDetailCommande = () => {
        this.setState({ open: false, commande: '', listeArticlesSelected: [] });

    };

    render() {
        const {
            open,
            // listeArticlesSelected,
            commandes,
            listeArticlesSelected,
            //nbresArticles,
            commande,
            clientListeArticlesSelected,


            isModalVisible,
            //loading,

            //agences,
            dateEnCours,

            //formLayout,

            isLoading,
            waitingEndMsg,

            endDate,
            startDate,

        } = this.state

        //  //console.log('listeArticlesSelected', listeArticlesSelected)
        ////console.log('commandesACT', commandes)

        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseInt(cur.qtCommander)
            }, 0) : 0

        //const InputGroup = Input.Group;
        //const Option = Select.Option;

        const { SearchBar } = Search;

        /*const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;*/


        const columnsCommandes = [
            {
                dataField: 'codPro',
                text: 'Code',
                headerAttrs: { width: 50 },
                //footer: 'TOTAL'
            },
            {
                dataField: 'designation',
                text: 'Désignation',
                headerAttrs: { width: 50 },
                //footer: ''
            },
            {
                dataField: 'prixUnitaire',
                text: 'Prix Unitaire',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                                row.prix ? `${numberWithCommas(parseInt(row.prix.prixUnitaire))} F Cfa` : '0 F Cfa'
                            }
                        </div>
                    );
                },
                // footer: ''

            },

            {
                dataField: 'qteTotal',
                text: 'Qté Total',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        <div className='text-primary text-center' style={{ fontWeight: 'bold' }}>{numberWithCommas(row.qteTotal)}</div>
                    );
                },
                //footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
                //footerClasses: 'demo-row-even',
                //footerAlign: 'center',
            },
            {
                dataField: 'montTotal',
                text: 'Montant Total',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        <div className='text-danger text-center' style={{ fontWeight: 'bold' }}>{numberWithCommas(row.montTotal)} F Cfa</div>
                    );
                },
                //footer: columnData => `${numberWithCommas(columnData.reduce((acc, item) => acc + item, 0))} F Cfa `,
                // footerClasses: 'demo-row-even',
                // footerAlign: 'center',
            },

            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//currentUser.slug === 'COMMERCIAL_ROLE' && row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDetailsCommande(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }

                        </div>
                    );
                },

            },

        ];


        const columnsCommandesPhone = [

            {
                dataField: 'designation',
                text: 'Désignation',
                headerAttrs: { width: 50 },
                //footer: 'TOTAL'

            },
            /*{
                dataField: 'prixUnitaire',
                text: 'Prix Unitaire',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                                row.prix ? `${numberWithCommas(parseInt(row.prix.prixUnitaire))} F Cfa` : '0 F Cfa'
                            }
                        </div>
                    );
                },
            },*/

            {
                dataField: 'qteTotal',
                text: 'Qté Total',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        <div className='text-primary text-center' style={{ fontWeight: 'bold' }}>{numberWithCommas(row.qteTotal)}</div>
                    );
                },
                //footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
                //footerClasses: 'demo-row-even',
                //  footerAlign: 'center',
            },
            /*{
                dataField: 'montTotal',
                text: 'Montant Total',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        <div className='text-danger text-center' style={{ fontWeight: 'bold' }}>{numberWithCommas(row.montTotal)} F Cfa</div>
                    );
                },
            },*/

            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//currentUser.slug === 'COMMERCIAL_ROLE' && row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="sm"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDetailsCommande(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }

                        </div>
                    );
                },

            },

        ];

        let totalChiffre = commandes && commandes.length ?
            commandes.reduce(function (prev, cur) {
                return prev + parseInt(cur.montTotal)
            }, 0) : 0

        const defaultSorted = [{
            dataField: 'designation',
            order: 'asc'
        }];


        return (
            <div>
                <Card title="Liste des Commandes Détaillés">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={commandes}
                            columns={columnsCommandes}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={8} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            {/*<Col md={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                <FormGroup>
                                                    <Select

                                                        value={codAgenceChange}
                                                        showSearch
                                                        //style={{width: 200}}
                                                        style={{ width: '140%' }}
                                                        placeholder="Filtrer par Agence"
                                                        optionFilterProp="children"
                                                        onChange={this.handleChangeAgence}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        <Option value=''>TOUTES</Option>
                                                        {
                                                            agences && agences.length ?
                                                                agences.map((agence, k) => {
                                                                    return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                }) : null
                                                        }

                                                    </Select>
                                                    <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                </FormGroup>

                                                    </Col>*/}
                                            {<Col md={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                <FormGroup className="mr-2">
                                                    <RangePicker className="gx-w-100"
                                                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                        format={dateFormat}
                                                        onChange={this.onDateChange}
                                                        ranges={{
                                                            "Aujourd'hui": [moment(), moment()],
                                                            'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                            'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                            'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                        }}
                                                    />
                                                    <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                </FormGroup>

                                                {/*} <FormGroup>

                                                    <DatePicker style={{ width: '100%' }} name="dateEnCours" id="dateEnCours" value={moment(dateEnCours, dateFormatList[0])} locale={locale} onChange={this.handleChangeDateFiltre} format={dateFormatList} />
                                                    <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Date</span></h6>
                                                    </FormGroup>*/}
                                            </Col>
                                            }
                                            {/* <Col md={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                <div className="col-md-6 date-range text-left">
                                                <FormGroup>
                                                    <DatePicker style={{ width: '100%' }} className="form-control digits ml-3"
                                                        selected={startDate}
                                                        onChange={this.handleChangeStartDate}
                                                        selectsStart
                                                        startDate={startDate}
                                                        endDate={endDate}

                                                        closeOnScroll={true}
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={fr}
                                                    />
                                                    <h6 className="ml-3" style={{ color: "black" }}><span>Du</span></h6>
                                                    </FormGroup>
                                                    </div>
                                                    <div className="col-md-6 date-range text-left">
                                                    <FormGroup>
                                                    <DatePicker style={{ width: '100%' }} className="form-control digits"
                                                        selected={endDate}
                                                        onChange={this.handleChangeEndDate}
                                                        selectsEnd
                                                        endDate={endDate}
                                                        minDate={startDate}

                                                        closeOnScroll={true}
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={fr}
                                                    />
                                                    <h6 className="" style={{ color: "black" }}><span>Au</span></h6>
                                                    </FormGroup>
                                                </div>
                                                
                                               
                                                {/*<Nav tabs className="border-tab">  
                                            <NavItem><NavLink className={etatCmde === "EN COURS" ? "active" : ''} onClick={() => this.handleEtatCommande("EN COURS")} style={{ cursor: 'pointer' }}><Target />{"EN COURS"}</NavLink></NavItem>
                                            <NavItem><NavLink className={etatCmde === "SERVIR" ? "active" : ''} onClick={() => this.handleEtatCommande("SERVIR")} style={{ cursor: 'pointer' }}><Info />{"SERVIR"}</NavLink></NavItem>
                                            <NavItem><NavLink className={etatCmde === "FACTUREE" ? "active" : ''} onClick={() => this.handleEtatCommande("FACTUREE")} style={{ cursor: 'pointer' }}><CheckCircle />{"FACTUREE"}</NavLink></NavItem>
                                         </Nav>*}
                                            </Col>*/}
                                            {/*use_logiciel === 'NO' && //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&

                                                <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>

                                        */ }
                                        </Row>

                                        {commandes &&
                                            Array.isArray(commandes) &&
                                            commandes.length > 0 &&
                                            <Row>
                                                <Col>
                                                    <h3 style={{ color: "red", fontWeight: "bold" }}><u>Total Vente</u>: {(totalChiffre === 1) ? 0 : `${numberWithCommas(totalChiffre)} F CFA`}</h3>
                                                </Col>
                                            </Row>
                                        }

                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {
                                            commandes &&
                                                Array.isArray(commandes) &&
                                                commandes.length > 0 ?

                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    //bootstrap4
                                                    wrapperClasses="table-responsive"
                                                    //selectRow={ selectRow }
                                                    //defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                    striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement "

                                                //filter={filterFactory()}
                                                />
                                                :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={commandes}
                            columns={columnsCommandesPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 450, width: '125%', display: 'flex' }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            {/*<Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                <Select

                                                    value={codAgence}
                                                    showSearch
                                                    //style={{width: 200}}
                                                    style={{ width: '85%' }}
                                                    placeholder="Filtrer par Agence"
                                                    optionFilterProp="children"
                                                    onChange={this.handleChangeAgence}
                                                    onFocus={this.handleFocus}
                                                    onBlur={this.handleBlur}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    <Option value=''>TOUTES</Option>
                                                    {
                                                        agences && agences.length ?
                                                            agences.map((agence, k) => {
                                                                return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                            }) : null
                                                    }

                                                </Select>
                                                <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                </Col>*/}


                                            <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                <FormGroup className="mr-2">
                                                    <RangePicker className="gx-w-100"
                                                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                        format={dateFormat}
                                                        onChange={this.onDateChange}
                                                        ranges={{
                                                            "Aujourd'hui": [moment(), moment()],
                                                            'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                            'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                            'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                        }}
                                                    />
                                                    <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                </FormGroup>

                                                {/* <FormGroup>
                                                    <DatePicker style={{ width: '100%' }} name="dateEnCours" id="dateEnCours" value={moment(dateEnCours, dateFormatList[0])} locale={locale} onChange={this.handleChangeDateFiltre} format={dateFormatList} />
                                                    <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Date</span></h6>
                                                </FormGroup>
                                                    */}
                                            </Col>
                                            {/*<Col md={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', }}>

                                                <Select
                                                    //style={{ maxWidth: 650, width: '90%', margin: 'auto', display: 'flex', borderRadius: 50 }}

                                                    value={etatCmde}
                                                    showSearch
                                                    //style={{width: 200}}
                                                    style={{ width: '90%' }}
                                                    placeholder="Filtrer par statut"
                                                    optionFilterProp="children"
                                                    onChange={this.handleChangeEtatCmde}
                                                    onFocus={this.handleFocus}
                                                    onBlur={this.handleBlur}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >

                                                    <Option value="EN COURS">EN COURS</Option>
                                                    <Option value="VALIDER">VALIDER</Option>

                                                </Select>

                                </Col>*/}
                                            {/*use_logiciel === 'NO' && //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&

                                                <Col className="mt-2" md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>
                            */}

                                        </Row>
                                        {commandes &&
                                            Array.isArray(commandes) &&
                                            commandes.length > 0 &&
                                            <Row>
                                                <Col>
                                                    <h3 style={{ color: "red", fontWeight: "bold" }}><u>Total Vente</u>: {(totalChiffre === 1) ? 0 : `${numberWithCommas(totalChiffre)} F CFA`}</h3>
                                                </Col>
                                            </Row>
                                        }

                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {
                                            commandes &&
                                                Array.isArray(commandes) &&
                                                commandes.length > 0 ?
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    //bootstrap4
                                                    wrapperClasses="table-responsive"
                                                    //selectRow={ selectRow }
                                                    //defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                    striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                                //filter={filterFactory()}
                                                />
                                                :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>



                <Modal
                    width={900}
                    visible={isModalVisible}
                    title="Détails Commande"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,

                    ]}
                >


                    <Row>
                        <Col md={12}>
                            <h5 style={{ color: "#1D75BB" }} >Commande N°{commande.numeroCmde || ''} </h5>
                            <div className="text-left">
                                <p>Quantité: {numberWithCommas(nbresArticles)} / Total: {numberWithCommas(parseInt(commande.netaPayer))} F Cfa</p>
                                <p>Agent: {commande.agent} / Observation: {commande.observation || "RAS"}</p>

                            </div>



                        </Col>
                    </Row>

                    <Media query="(max-width: 767px)" render={() =>
                    (

                        <Row>
                            <Col md={12}>

                                <div className="best-seller-table responsive-tbl">
                                    <div className="item">
                                        <div >
                                            <Table borderless /*style={{ maxHeight: '800', /*overflow: 'auto'/ }}*/ >
                                                <thead>
                                                    <tr>
                                                        <th >{"Désignation"}</th>
                                                        {/*<th>{"Prix Unitaire"}</th>*/}
                                                        <th>{"Quantité"}</th>
                                                        {/*<th>{"Net TTC"}</th>*/}
                                                    </tr>
                                                </thead>

                                                {
                                                    //SOUS BON COMMANDE
                                                    clientListeArticlesSelected && clientListeArticlesSelected.length ?
                                                        clientListeArticlesSelected.map((sousCommande, t) => {
                                                            return (
                                                                <tbody className="f-w-500">

                                                                    <h6 className="mt-3" style={{ color: "#1D75BB" }}>{`Sous Bon Commande N°: ${sousCommande.numSousCmde}`}</h6>

                                                                    {
                                                                        sousCommande.listeArticlesSelected && sousCommande.listeArticlesSelected.length ?
                                                                            sousCommande.listeArticlesSelected.map((article, k) => {
                                                                                return (
                                                                                    <tr key={k}>
                                                                                        <td>
                                                                                            <div className="media">
                                                                                                {article.article && article.article.imgLink ?
                                                                                                    <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.article && article.article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                    :
                                                                                                    <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                                }
                                                                                                <div className="media-body"><span>{article.article && article.article.designation}</span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        {/* <td>
                              <span>{numberWithCommas(parseInt(article.puUtilise))} F Cfa</span>
                           </td>*/}
                                                                                        <td>
                                                                                            <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                                                        </td>
                                                                                        {/*<td>
                              <span>{numberWithCommas( parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva) )/100)} F Cfa</span>
                            </td>*/}

                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                                                                    }
                                                                </tbody>

                                                            );


                                                        }) : <span style={{ color: '#1D75BB' }} >Aucun détails pour cette commande</span >
                                                }

                                            </Table>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                    )}
                    />

                    <Media query="(min-width: 768px)" render={() =>
                    (

                        <Row>
                            <Col md={12}>
                                <Card>


                                    <div className="best-seller-table responsive-tbl">
                                        <div className="item">
                                            <div className="table-responsive product-list">
                                                <Table borderless style={{ maxHeight: '800', overflow: 'auto' }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="f-22">{"Désignation"}</th>
                                                            {/*<th>{"Prix Unitaire"}</th>*/}
                                                            <th>{"Quantité"}</th>
                                                            <th>{"Net HT"}</th>
                                                        </tr>
                                                    </thead>


                                                    {
                                                        //SOUS BON COMMANDE
                                                        clientListeArticlesSelected && clientListeArticlesSelected.length ?
                                                            clientListeArticlesSelected.map((sousCommande, t) => {
                                                                return (
                                                                    <tbody className="f-w-500">

                                                                        <h6 className="mt-3" style={{ color: "#1D75BB" }}>{`Sous Bon Commande N°: ${sousCommande.numSousCmde}`}</h6>

                                                                        {sousCommande.listeArticlesSelected && sousCommande.listeArticlesSelected.length ?
                                                                            sousCommande.listeArticlesSelected.map((article, k) => {
                                                                                return (

                                                                                    <tr key={k}>
                                                                                        <td>
                                                                                            <div className="media">
                                                                                                {article.article && article.article.imgLink ?
                                                                                                    <img className="img-fluid m-r-15 rounded-circle" src={`${img_base_url}/images/produits/${article.article && article.article.imgLink}`} style={{ width: '30px', height: '30px' }} alt="Produit" />
                                                                                                    :
                                                                                                    <img style={{ width: '30px', height: '30px' }} className="img-fluid m-r-15 rounded-circle" src={productImg} alt="" />
                                                                                                }
                                                                                                <div className="media-body"><span>{article.article && article.article.designation}</span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        {/*<td>
                                      <span>{numberWithCommas(parseInt(article.puUtilise))} F Cfa</span>
                                    </td>*/}
                                                                                        <td>
                                                                                            <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{numberWithCommas(parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva)) / 100)} F Cfa</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                );
                                                                            })

                                                                            : <span style={{ color: '#1D75BB' }} >Aucun détails pour cette commande</span >
                                                                        }
                                                                    </tbody>

                                                                );


                                                            }) : <span style={{ color: '#1D75BB' }} >Aucun détails pour cette commande</span >
                                                    }







                                                </Table>

                                            </div>
                                        </div>
                                    </div>

                                </Card>
                            </Col>
                        </Row>

                    )}
                    />

                </Modal>

                <Modal className="modal-dialog modal-lg" centered={true} scrollable={true} isOpen={open}>

                    <ModalHeader toggle={this.onCloseModalDetailCommande}>

                        <Row>
                            <Col md={12}>
                                <h5 style={{ color: '#1D75BB' }}>Point {commande.designation || ''} du {dateEnCours}</h5>


                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody /*style={{ maxHeight: '800px', maxWidth:'800px', overflow: 'auto' }}*/ toggle={this.onCloseModalDetailCommande}>

                        <Row>
                            <Col md={12}>
                                <Media query="(max-width: 767px)" render={() =>
                                (
                                    <Table borderless style={{ maxHeight: '800', overflow: 'auto' }} >
                                        <thead>
                                            <tr>
                                                <th >{"N° Cmde"}</th>
                                                {/*<th >{"Agent"}</th>
                                                                        <th>{"Quantité"}</th>*/}
                                                <th>{"Net TTC"}</th>
                                            </tr>
                                        </thead>

                                        <tbody className="f-w-500" >
                                            {
                                                listeArticlesSelected && listeArticlesSelected.length ?
                                                    listeArticlesSelected.map((article, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                <td>
                                                                    <span>{article.numeroCmde || ''}</span>
                                                                    <p>Qte: {numberWithCommas(parseInt(article.qtCommander))}</p>
                                                                </td>
                                                                {/*<td>
                                                                                            <span>{article.agent}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                                                        </td>*/}
                                                                <td>
                                                                    <span>{numberWithCommas(parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva)) / 100)} F Cfa</span>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })
                                                    : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                                            }


                                        </tbody>
                                    </Table>
                                )
                                } />

                                <Media query="(min-width: 768px)" render={() =>
                                (
                                    <Card>

                                        <CardBody className="pt-0">
                                            <div className="best-seller-table responsive-tbl">
                                                <div className="item">
                                                    <div>
                                                        <Table borderless style={{ maxHeight: '800', overflow: 'auto' }} >
                                                            <thead>
                                                                <tr>
                                                                    <th >{"N° Cmde"}</th>
                                                                    <th >{"Agent"}</th>
                                                                    <th>{"Quantité"}</th>
                                                                    <th>{"Net TTC"}</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="f-w-500" >
                                                                {
                                                                    listeArticlesSelected && listeArticlesSelected.length ?
                                                                        listeArticlesSelected.map((article, k) => {
                                                                            return (
                                                                                <tr key={k}>
                                                                                    <td>
                                                                                        <span>{article.numeroCmde || ''}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{article.agent}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{numberWithCommas(parseInt(article.qtCommander))}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{numberWithCommas(parseInt(article.puNetHt) + (parseInt(article.puNetHt * article.tauxTva)) / 100)} F Cfa</span>
                                                                                    </td>

                                                                                </tr>
                                                                            );
                                                                        })
                                                                        : <span style={{ color: '#7366ff' }} >Aucun détails pour cette commande</span >
                                                                }


                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                )
                                } />







                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>

                        <Button color="secondary" onClick={this.onCloseModalDetailCommande}>{"Fermer"}</Button>

                    </ModalFooter>
                </Modal>



            </div>
        )
    }
}


export default CmdPointsDetails;
