import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

import {authenticationService} from "../../services/authentication-service";
//import {services} from "../../services/services";

import { Link } from "react-router-dom";

const signOut = (e) => {
  e.preventDefault()
  authenticationService.logout()
  this.props.history.push('/signup')
}

//const currentUser = authenticationService.currentUserValue

class UserInfo extends Component {

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li><Link to="/profile">Profil</Link></li>
       
       <li onClick={signOut/*() => this.props.userSignOut()*/}>Se Déconnecter
       </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar  src={require("assets/images/User.png")} //src='https://via.placeholder.com/150x150'
                className="gx-avatar gx-pointer" alt=""/>
      </Popover>
    )

  }
}

export default connect(null, {userSignOut})(UserInfo);
