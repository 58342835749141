import React from "react";
import {Alert, Button, Checkbox, Form,  Input, message} from "antd";
import {connect} from "react-redux";
//import {Link} from "react-router-dom";

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from "appRedux/actions/Auth";
//import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

import { authenticationService } from "../services/authentication-service";
//import { services } from '../services/services';

//const moment = require('moment')
const currentUser = authenticationService.currentUserValue;


const FormItem = Form.Item;

class SignIn extends React.Component {
  state={
    errAlert: false,
    loading: false,
    ip:"",
    location:"",
    longitude:"",
    latitude:"",
    departement:"",
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    //const {username, password} = this.state
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })

        
        authenticationService.login(this.state.username, this.state.password, this.state.ip, this.state.location, this.state.departement, this.state.longitude, this.state.latitude)
          .then(user => {
              this.props.showAuthLoader();
              //console.log('user result', user)
              const { from } = this.props.location.state || { from: { pathname: "/" } }
              this.props.history.push(from)
              window.location.reload()
              //this.props.history.push(from);
              //setTimeout(() => window.location.reload() , 500)
              this.setState({ loading: false })
              
              
          })
          .catch(err => {
            this.setState({errAlert:true, loading: false})
            setTimeout(() => this.setState({ errAlert: false }), 5000)
            //console.log('ERR', err)
          })

        //this.props.userSignIn(values);
      }
    });
  };

  componentDidMount() {
    const { from } = this.props.location.state || { from: { pathname: "/" } }
    
    currentUser !== null &&
    this.props.history.push(from)
     // setTimeout(() => this.props.history.push(from), 100)
    //localStorage.removeItem('currentUser');
    /*services.getStart()
      .then(start => {
        //console.log('start', start)
        this.setState({ start })
      })*/
      //console.log("DATE", moment.utc(new Date()).utcOffset('+10:00'))
      
      /*fetch(
        "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
    )
        .then(response => response.json())
        .then((data) => {
          console.log("DATA", data)
          this.setState(
            {data,
            ip: data.IPv4 || '',
            location: `${data.city}, ${data.country_name}(${data.country_code})` || '',
            departement: data.state || '',
            latitude: data.latitude || '',
            longitude: data.longitude || '', 
          }
            )
        } );
        */


  }
  
  componentDidUpdate() {
    /*if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }*/
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage} = this.props;
    const {errAlert, loading} = this.state
    
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={require("assets/images/login.jpeg")} /*"https://via.placeholder.com/272x395"*/ alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
               {/* <h1>CONNEXION</h1>
                <p><IntlMessages id="app.userAuth.bySigning"/></p>
               <p><IntlMessages id="app.userAuth.getAccount"/></p>*/}
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('text', {
                    //initialValue: "Utilisateur",
                    rules: [{
                      required: true, /*type: 'text',*/ message: 'Veuillez préciser votre identifiant!',
                    }],
                  })(
                    <Input name="username" onChange={this.handleChange} placeholder="Utilisateur"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    //initialValue: "demo#123",
                    rules: [{required: true, message: 'Veuillez préciser votre mot de passe!'}],
                  })(
                    <Input type="password" name="password" onChange={this.handleChange} placeholder="Password"/>
                  )}
                </FormItem>
                { errAlert === true &&
                <Alert message="Identifiant ou mot de passe incorrect" type="error" showIcon/>
                }
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox>en me connectant, j'accepte les </Checkbox>
                  )}
                  <span className="gx-signup-form-forgot gx-link">Termes et conditions</span>
                </FormItem>
                
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit" disabled={loading ? loading : loading}>
                  {loading ? "CONNEXION EN COURS..." : "SE CONNECTER"}
                  </Button>
                 {/* <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp"/></Link>*/}
                </FormItem>
                <div className="gx-flex-row gx-justify-content-between">
                  {/*<span>or connect with</span>
                  <ul className="gx-social-link">
                    <li>
                      <Icon type="google" onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userGoogleSignIn();
                      }}/>
                    </li>
                    <li>
                      <Icon type="facebook" onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userFacebookSignIn();
                      }}/>
                    </li>
                    <li>
                      <Icon type="github" onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userGithubSignIn();
                      }}/>
                    </li>
                    <li>
                      <Icon type="twitter" onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userTwitterSignIn();
                      }}/>
                    </li>
                    </ul> */}
                </div>
                <span
                  className="gx-text-light gx-fs-sm"> Pour tout problème de connexion, veuillez contacter le service technique au 97 59 87 36. {/*demo user email: 'demo@example.com' and password: 'demo#123'*/}</span>
              </Form>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(WrappedNormalLoginForm);
